import styled from 'styled-components';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import { up } from 'styled-breakpoints';

export const SplitLayoutScrollWheelHandler = styled(ReactScrollWheelHandler)`
    position: fixed; /* makes sure that on mobile their is no default scroll behavior */
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    outline: 0;
    overflow: hidden;

    ${up('lg')} {
        flex-direction: row;
    }
`;

export const SplitLayoutMainContent = styled.main`
    /* stylelint-disable function-parentheses-newline-inside, no-eol-whitespace */
    position: relative;
    flex: 1 1 auto;
    padding: 0 40px 55px 40px;
    overflow-x: hidden; /* added to cut of search list items on mobile */
    overflow-y: hidden;

    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(
            to bottom,
            #170b01,
            #170b01 calc(50% + 31px),
            transparent calc(50% + 31px + 50px),
            transparent,
            transparent calc(100vh - 70px),
            #170b01 calc(100vh - 55px),
            #170b01
        );
        pointer-events: none;
    }

    ${up('sm')} {
        padding: 0 50px 55px;
    }

    ${up('md')} {
        &:after {
            background-image: linear-gradient(
                to bottom,
                #170b01,
                #170b01 70%,
                transparent 80%,
                transparent,
                transparent 90%,
                #170b01 95%,
                #170b01
            );
        }
    }

    ${up('lg')} {
        flex: 1 1 56%;
        height: 100vh;
        margin: 0;
        padding: 40vh 65px 40vh 50px;

        &:after {
            background-image: linear-gradient(
                to bottom,
                #170b01,
                #170b01 240px,
                transparent 45%,
                transparent,
                transparent 75%,
                #170b01 95%,
                #170b01
            );
        }
    }

    ${up('xl')} {
        padding: 40vh 160px 40vh 150px;

        &:after {
            background-image: linear-gradient(
                to bottom,
                #170b01,
                #170b01 290px,
                transparent 400px,
                transparent,
                transparent 70%,
                #170b01 85%,
                #170b01
            );
        }
    }
`;

// @todo fix bugs on mobile side container height
// try to make the child figure the flex item with a set height
export const SplitLayoutSide = styled.aside`
    position: relative;
    display: flex;
    flex: none;
    justify-content: center;
    padding: 40px;

    ${up('sm')} {
        padding: 50px;
    }

    ${up('lg')} {
        flex: 1 1 44%;
        height: auto;
        padding: 0;
    }
`;
