import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import {
    DeclarationSignSummary,
    DeclarationSignType,
    isTattooedLetter,
    TattooedLetterSummary,
} from '../../../api/response/types';

export type OnClickCallback = (tattooedLetter: TattooedLetterSummary) => void;

type StyledProps = {
    current: boolean;
    sign: DeclarationSignSummary;
};

type OtherProps = {
    identifier?: string;
    onClick: OnClickCallback;
};

type Props = OtherProps & StyledProps;

const StyledLetterIsPut = styled.span<StyledProps>`
    color: ${props => props.theme.colors.default};

    ${props =>
        props.current &&
        css`
            color: ${props.theme.colors.primary};
            text-decoration: underline;
        `};

    ${({ sign }) =>
        sign.type === DeclarationSignType.Tattoo &&
        css`
            cursor: pointer;
        `};
`;

const StyledLetterIsNotPut = styled(StyledLetterIsPut)`
    color: ${props => props.theme.colors.primary};
    opacity: ${props => (props.current ? 1 : 0.5)};
`;

const PunctuationMark = styled(StyledLetterIsPut)`
    /* this should make punctuation the color of the preceding sign. But fails when starting with punctuation in an article which is not set, we might need more js logic for this */
    /* stylelint-disable rule-empty-line-before, declaration-block-semicolon-newline-after */
    ${StyledLetterIsNotPut} + &,
    ${StyledLetterIsNotPut} ~ & > sub {
        color: ${props => props.theme.colors.primary};
        opacity: 0.5;
    }
`;

function prepareSign(sign: string): JSX.Element | string {
    if (sign === '') {
        return ' ';
    }

    if (sign.match(/^[()0-9]$/)) {
        return <sub>{sign}</sub>;
    }

    return sign;
}

const Sign: React.FC<Props> = ({
    sign,
    current,
    onClick: onClickHandler,
    identifier,
}) => {
    const onClick = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();

            if (isTattooedLetter(sign)) {
                onClickHandler(sign);
            }
        },
        [sign, onClickHandler]
    );

    const preparedChar = prepareSign(sign.sign);
    const ComposedComponent = isTattooedLetter(sign)
        ? sign.isPut
            ? StyledLetterIsPut
            : StyledLetterIsNotPut
        : PunctuationMark;

    return (
        <ComposedComponent
            current={current}
            sign={sign}
            onClick={onClick}
            id={identifier || undefined}
        >
            {preparedChar}
        </ComposedComponent>
    );
};

export default Sign;
