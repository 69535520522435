import { SetNewCursorCallback } from './../ArticleDetail';
import {
    LetterCursor,
    determineNewTattooedLetterCursor,
    determineNextDisplayableTattooedLetterNumberInCollection,
} from './../../declarationScroller/utility/signCollectionNavigationHelper';
import { useCallback, MutableRefObject } from 'react';
import { ArticleWithSignsSummaryCollection } from '../../../api/response/types';

export const createDirectionChangeHandler = (
    getCurrentCursor: () => LetterCursor,
    articlesRef: MutableRefObject<
        ArticleWithSignsSummaryCollection | undefined
    >,
    noOfArticles: number | undefined,
    setNewCursor: SetNewCursorCallback
) => {
    return useCallback(
        ({ direction }) => {
            const currentCursor = getCurrentCursor();
            const articles = articlesRef.current;

            if (!articles || !noOfArticles || !currentCursor) {
                return;
            }

            const newCursor = determineNewTattooedLetterCursor(
                currentCursor,
                articles,
                noOfArticles,
                direction
            );

            if (!newCursor) {
                return;
            }

            setNewCursor(newCursor);
        },
        [noOfArticles]
    );
};

export const createOnArticleClickHandler = (
    setNewCursor: SetNewCursorCallback
) => {
    return useCallback(newArticle => {
        // when index is set to -1, as soon as current signs are available, the new index
        // is resolved (due to characters that might be first that are not allowed to be selected)

        setNewCursor({
            article: newArticle,
            letterNumber: -1,
        });
    }, []);
};

export const createOnLetterClickHandler = (
    getCurrentCursor: () => LetterCursor,
    setNewCursor: SetNewCursorCallback
) => {
    return useCallback(clickedTattooedLetter => {
        const currentCursor = getCurrentCursor();

        if (!clickedTattooedLetter.number || !currentCursor) {
            return;
        }

        setNewCursor({
            article: currentCursor.article,
            letterNumber: clickedTattooedLetter.number,
        });
    }, []);
};

export const createOnFirstLetterCouldNotBeDeterminedHandler = (
    getCurrentCursor: () => LetterCursor,
    articlesRef: MutableRefObject<
        ArticleWithSignsSummaryCollection | undefined
    >,
    setNewCursor: SetNewCursorCallback
) => {
    return () => {
        const articles = articlesRef.current;

        if (!articles) {
            return;
        }

        const currentCursor = getCurrentCursor();

        if (!currentCursor) {
            return;
        }

        const currentSigns = articles[currentCursor.article];

        if (!currentSigns) {
            return;
        }

        const newNumber = determineNextDisplayableTattooedLetterNumberInCollection(
            currentSigns
        );

        if (!newNumber) {
            return;
        }

        setNewCursor({
            article: currentCursor.article,
            letterNumber: newNumber,
        });
    };
};
