import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import {
    SplitLayoutMainContent,
    SplitLayoutSide,
} from '../../primitives/SplitLayout';
import { StyledPortraitImage } from '../../tattooedLetterPreview/TattooedLetterPreviewStyles';

// @todo remove duplication between below and SplitLayoutScrollWheelHandler
const StyledWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    outline: 0;
    overflow: hidden;

    ${up('lg')} {
        flex-direction: row;
    }
`;

const PlaceholderPortrait = styled(StyledPortraitImage)`
    background-color: #816652;
`;

const PlaceholderPortraitWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

// @todo remove duplication with StyledTattooImage
// @todo fix bug not displaying small image
const TattooImagePlaceholder = styled.div`
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 100px;
    height: 100px;
    margin: 0;
    transform: translate(0, 50%);
    box-shadow: 0 0 50px #000;
    background: #a98e7a;

    ${up('lg')} {
        top: 50%;
        left: 0;
        bottom: auto;
        width: 200px;
        height: 200px;
        transform: translate(-50%, -50%);
    }
`;

// @todo get this to work correctly on mobile too

const LoadingLayout: React.FC = () => (
    <div>
        <StyledWrapper>
            <SplitLayoutMainContent>
                {/* @todo get this centered in the middle of the page + animate it like it is typed */}
                <h3>Loading...</h3>
            </SplitLayoutMainContent>
            <SplitLayoutSide>
                <PlaceholderPortraitWrapper>
                    <PlaceholderPortrait as="div" />
                    <TattooImagePlaceholder />
                </PlaceholderPortraitWrapper>
            </SplitLayoutSide>
        </StyledWrapper>
    </div>
);

export default LoadingLayout;
