import { TattooedLetterDetail } from './../../api/response/types';
import { ActionType, getType } from 'typesafe-actions';
import * as actionFactories from '../actionFactory/tattooedLetterDetailActionFactory';
import { RootAction } from '../types';

export type TattooedLetterDetailAction = ActionType<typeof actionFactories>;

export type TattooedLetterDetailReducerState = TattooedLetterDetail | null;

export const DEFAULT_STATE: TattooedLetterDetailReducerState = null;

const tattooedLetterDetailReducer = (
    currentState: TattooedLetterDetailReducerState = DEFAULT_STATE,
    action: RootAction
) => {
    switch (action.type) {
        case getType(
            actionFactories.createFetchTattooedLetterDetailSuccessAction
        ):
            return action.payload;

        case getType(actionFactories.createClearTattooedLetterDetailAction):
            return null;

        default:
            return currentState;
    }
};

export default tattooedLetterDetailReducer;
