import {
    TattooedLetterSummaryCollection,
    TattooedLetterSummary,
} from '../../../api/response/types';

export function determineNextHighlightedLetterNumber(
    currentHighlightedLetter: TattooedLetterSummary,
    results: TattooedLetterSummaryCollection
): number | null {
    const currentIndex = results.findIndex(
        cursorLetter => cursorLetter.id === currentHighlightedLetter.id
    );

    if (currentIndex === -1) {
        return null;
    }

    const nextLetter = results[currentIndex + 1];

    return nextLetter ? nextLetter.number : null;
}

export function determinePreviousHighlightedLetterNumber(
    currentHighlightedLetter: TattooedLetterSummary,
    results: TattooedLetterSummaryCollection
): number | null {
    const currentIndex = results.findIndex(
        cursorLetter => cursorLetter.id === currentHighlightedLetter.id
    );

    if (currentIndex === -1 || currentIndex - 1 < 0) {
        return null;
    }

    const previousLetter = results[currentIndex - 1];

    return previousLetter ? previousLetter.number : null;
}
