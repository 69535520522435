import React, { useCallback } from 'react';
import { TagOptions } from '../../primitives/Heading';
import {
    StyledDeclarationArticle,
    StyledHeading,
    StyledParagraph,
} from './DeclarationStyles';

export type OnClickCallback = (article: number) => void;

type Props = {
    articleNumber: number;
    children?: React.ReactNode;
    onClick: OnClickCallback;
};

const DeclarationArticle: React.FC<Props> = ({
    articleNumber,
    children,
    onClick: onClickHandler,
}) => {
    const onCLick = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();

            onClickHandler(articleNumber);
        },
        [articleNumber]
    );

    return (
        <StyledDeclarationArticle>
            <StyledHeading tag={TagOptions.h2} flatten={true} onClick={onCLick}>
                Article {articleNumber}
            </StyledHeading>
            {children && <StyledParagraph>{children}</StyledParagraph>}
        </StyledDeclarationArticle>
    );
};

export default DeclarationArticle;
