import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

interface Props {
    currentCount: number;
    totalCount: number;
}

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 10px 55px;
    font-size: ${props => props.theme.fontSizes.smallFixed};

    ${up('sm')} {
        margin: 10px 65px;
    }

    ${up('lg')} {
        /* no space anymore! hide for now */
        display: none;
    }

    ${up('xl')} {
        position: fixed;
        top: 50%;
        left: 50px;
        display: flex;
        margin: 0;
        transform: translateY(-3px);
        line-height: 1;
    }
`;

const StyledCurrentCount = styled.span`
    color: ${props => props.theme.colors.primary};
`;

const StyledDivider = styled.span`
    margin: 0 0.5em;
`;

const DeclarationCounter: React.FC<Props> = ({ currentCount, totalCount }) => {
    return (
        <StyledWrapper>
            <StyledCurrentCount>{currentCount}</StyledCurrentCount>
            <StyledDivider>/</StyledDivider>
            <span>{totalCount}</span>
        </StyledWrapper>
    );
};

export default DeclarationCounter;
