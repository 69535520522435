import axios, { Canceler, AxiosPromise } from 'axios';

export type CancelCallback = Canceler | null;

export function executeGetRequest<ResponseType>(
    path: string
): [AxiosPromise<ResponseType>, CancelCallback] {
    let cancel: CancelCallback = null;

    const cancelToken = new axios.CancelToken(c => (cancel = c));

    const promise = axios.get<ResponseType>(path, { cancelToken });

    return [promise, cancel];
}
