import { LetterCursor } from './signCollectionNavigationHelper';
import {
    DeclarationSignSummaryCollection,
    isTattooedLetter,
    ArticleWithSignsSummaryCollection,
} from './../../../api/response/types';
import { NavigationDirection } from '../DeclarationScroller';

export type LetterCursor = {
    letterNumber: number;
    article: number;
};

export function determineNextArticle(
    currentArticle: number,
    noOfArticles: number
): number | false {
    return currentArticle + 1 <= noOfArticles ? currentArticle + 1 : false;
}

export function determinePreviousArticle(
    currentArticle: number
): number | false {
    return currentArticle - 1 > 0 ? currentArticle - 1 : false;
}

export function determineNextDisplayableTattooedLetterNumberInCollection(
    signs: DeclarationSignSummaryCollection,
    startIndex: number = 0
): number | null {
    for (
        let cursorIndex = startIndex, totalNoOfSigns = signs.length;
        cursorIndex < totalNoOfSigns;
        cursorIndex++
    ) {
        const cursorSign = signs[cursorIndex];

        if (isTattooedLetter(cursorSign)) {
            return cursorSign.number;
        }
    }

    return null;
}

export function determineFirstPutTattooedLetterNumberInCollection(
    signs: DeclarationSignSummaryCollection
): number | null {
    for (
        let cursorIndex = 0, totalNoOfSigns = signs.length;
        cursorIndex < totalNoOfSigns;
        cursorIndex++
    ) {
        const cursorSign = signs[cursorIndex];

        if (isTattooedLetter(cursorSign) && cursorSign.isPut) {
            return cursorSign.number;
        }
    }

    return null;
}

function createCursorForFirstTattooedLetterInNextArticle(
    currentArticle: number,
    noOfArticles: number,
    nextSigns: DeclarationSignSummaryCollection | null
): LetterCursor | false {
    const nextArticle = determineNextArticle(currentArticle, noOfArticles);

    if (nextArticle === false || !nextSigns) {
        return false;
    }

    const nextNumber = determineNextDisplayableTattooedLetterNumberInCollection(
        nextSigns
    );

    if (!nextNumber) {
        throw new Error('No next article cursor could be created');
    }

    return {
        letterNumber: nextNumber,
        article: nextArticle,
    };
}

export function determineNewTattooedLetterCursor(
    currentCursor: LetterCursor,
    articles: ArticleWithSignsSummaryCollection,
    noOfArticles: number,
    direction: NavigationDirection
): LetterCursor | false {
    const currentSigns = articles[currentCursor.article];

    const nextArticle = determineNextArticle(
        currentCursor.article,
        noOfArticles
    );
    const nextSigns = nextArticle ? articles[nextArticle] : undefined;

    const previousArticle = determinePreviousArticle(currentCursor.article);
    const previousSigns = previousArticle
        ? articles[previousArticle]
        : undefined;

    switch (direction) {
        case NavigationDirection.Forward:
            if (!nextSigns) {
                return false;
            }

            return determineNextTattooedLetterCursor(
                currentSigns,
                nextSigns,
                currentCursor,
                noOfArticles
            );

        case NavigationDirection.Back:
            if (!previousSigns) {
                return false;
            }

            return resolvePreviousTatooedLetterCursor(
                currentSigns,
                previousSigns,
                currentCursor
            );

        default:
            throw new Error(
                `Navigation direction '${direction}' not supported`
            );
    }
}

export function determineNextTattooedLetterCursor(
    currentSigns: DeclarationSignSummaryCollection,
    nextSigns: DeclarationSignSummaryCollection | null,
    currentCursor: LetterCursor,
    noOfArticles: number
): LetterCursor | false {
    if (currentSigns.length === 0) {
        throw new Error('No signs supplied to search in');
    }

    const currentIndex = determineIndexOfTattooedLetterWithNumber(
        currentSigns,
        currentCursor.letterNumber
    );

    if (currentIndex === null) {
        return false;
    }

    const lastIndex: number = currentSigns.length - 1;

    if (currentIndex + 1 > lastIndex) {
        // no next character, move to next article

        return createCursorForFirstTattooedLetterInNextArticle(
            currentCursor.article,
            noOfArticles,
            nextSigns
        );
    }

    // at this point we know there are still new characters in the current article. What we however
    // do not know, is if these are tattooed letters.

    const nextLetterNumber = determineNextDisplayableTattooedLetterNumberInCollection(
        currentSigns,
        currentIndex + 1
    );

    if (!nextLetterNumber) {
        return createCursorForFirstTattooedLetterInNextArticle(
            currentCursor.article,
            noOfArticles,
            nextSigns
        );
    }

    return {
        letterNumber: nextLetterNumber,
        article: currentCursor.article,
    };
}

export function resolvePreviousTattooedLetterNumberInCollection(
    signs: DeclarationSignSummaryCollection,
    startIndex?: number
): number | null {
    for (
        let cursorIndex =
            typeof startIndex !== 'undefined' ? startIndex : signs.length - 1;
        cursorIndex >= 0;
        cursorIndex--
    ) {
        const cursorSign = signs[cursorIndex];

        if (isTattooedLetter(cursorSign)) {
            return cursorSign.number;
        }
    }

    return null;
}

function createCursorForPreviousArticle(
    currentArticle: number,
    previousSigns: DeclarationSignSummaryCollection | null
): LetterCursor | false {
    const previousArticle = determinePreviousArticle(currentArticle);

    if (previousArticle === false || !previousSigns) {
        return false;
    }

    const previousNumber = resolvePreviousTattooedLetterNumberInCollection(
        previousSigns
    );

    if (!previousNumber) {
        return false;
    }

    return {
        letterNumber: previousNumber,
        article: previousArticle,
    };
}

export function resolvePreviousTatooedLetterCursor(
    currentSigns: DeclarationSignSummaryCollection,
    previousSigns: DeclarationSignSummaryCollection | null,
    currentCursor: LetterCursor
): LetterCursor | false {
    if (currentSigns.length === 0) {
        throw new Error('No signs supplied to search in');
    }

    const currentIndex = determineIndexOfTattooedLetterWithNumber(
        currentSigns,
        currentCursor.letterNumber
    );

    if (currentIndex === null) {
        return false;
    }

    if (currentIndex - 1 < 0) {
        // no previous character, move to previous article

        return createCursorForPreviousArticle(
            currentCursor.article,
            previousSigns
        );
    }

    const previousNumber = resolvePreviousTattooedLetterNumberInCollection(
        currentSigns,
        currentIndex - 1
    );

    if (!previousNumber) {
        return createCursorForPreviousArticle(
            currentCursor.article,
            previousSigns
        );
    }

    return {
        letterNumber: previousNumber,
        article: currentCursor.article,
    };
}

export function determineIndexOfTattooedLetterWithNumber(
    signs: DeclarationSignSummaryCollection,
    tattooedLetterNumber: number
): number | null {
    const index = signs.findIndex(
        cursorSign =>
            isTattooedLetter(cursorSign) &&
            cursorSign.number === tattooedLetterNumber
    );

    return index >= 0 ? index : null;
}
