import React from 'react';
import { TextBlock } from '../../../api/response/types';
import RawContent from '../../primitives/RawContent';
import styled from 'styled-components';

type Props = {
    block: TextBlock;
};

const StyledTextContentBlock = styled.article`
    margin: 2rem 0;
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.typography.fontFamilyTertiary};
    font-size: ${props => props.theme.fontSizes.small};

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: ${props => props.theme.typography.fontFamilySecondary};
    }

    a {
        color: ${props => props.theme.colors.dark};
        text-decoration: underline;
    }
`;

const TextContentBlock: React.FC<Props> = ({ block }) => (
    <StyledTextContentBlock>
        <RawContent content={block.text.html} />
    </StyledTextContentBlock>
);

export default TextContentBlock;
