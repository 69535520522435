import { checkVideoHasPlayedForThisClient } from './../../../repository/clientSettingsRepository';
import { useState } from 'react';
import { useOnMount } from '../../../hooks/useOnMount';

export default function useCheckIfHeaderShouldBeDisplayed(): boolean {
    // @todo below is a workaround to hide the header for a while, until the intro video has stopped playback, to prevent
    // it is displayed on mount. A better solution would be to store this in Redux, but there is no time for this now.
    // Refactor later on?

    const introVideoHasPlayed = checkVideoHasPlayedForThisClient();

    const [display, setDisplay] = useState<boolean>(introVideoHasPlayed);

    useOnMount(() => {
        let timeoutHandle: number;

        if (display === false) {
            timeoutHandle = setTimeout(() => setDisplay(true), 5000);
        }

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    });

    return display;
}
