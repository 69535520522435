import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
    article: number;
};

const Meta: React.FC<Props> = React.memo(({ article }) => (
    <Helmet>
        <title>{`Article ${article} - Human Rights Tattoo`}</title>
    </Helmet>
));

export default Meta;
