import {
    checkVideoHasPlayedForThisClient,
    saveVideoHasPlayedForThisClient,
} from './../../../repository/clientSettingsRepository';
import { useState, useEffect, RefObject } from 'react';

export default function useDetermineIntroVideoHasPlayed(
    videoRef: RefObject<HTMLVideoElement>
) {
    const videoHasPlayedBefore = checkVideoHasPlayedForThisClient();
    const [hasEnded, setHasEnded] = useState<boolean>(false);

    const onVideoEnded = () => {
        setHasEnded(true);

        saveVideoHasPlayedForThisClient();
    };

    useEffect(() => {
        const video = videoRef.current;

        if (video && !videoHasPlayedBefore) {
            video.addEventListener('ended', onVideoEnded);
        }

        return () => {
            const video = videoRef.current;

            if (video) {
                video.removeEventListener('ended', onVideoEnded);
            }
        };
    }, [videoRef]);

    return { hasEnded, videoHasPlayedBefore };
}
