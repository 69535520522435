import React, { useCallback } from 'react';
import Sign, {
    OnClickCallback as OnLetterlickCallback,
} from './components/Sign';
import useLockHtmlScroll from '../../hooks/useLockHtmlScroll';
import {
    DeclarationSignSummaryCollection,
    isTattooedLetter,
} from '../../api/response/types';
import {
    SplitLayoutScrollWheelHandler,
    SplitLayoutMainContent,
    SplitLayoutSide,
} from '../primitives/SplitLayout';
import DeclarationArticle, {
    OnClickCallback as OnArticleClickCallback,
} from './components/DeclarationArticle';
import { createNumberRangeArray } from '../../utility/arrayUtilities';
import { checkIfRouteNameIsInCurrentMatchedRoutes } from '../../routing/utility/routeMatchUtilities';
import { LetterCursor } from './utility/signCollectionNavigationHelper';
import useScrollToLetterOnWindowResize from './hooks/useScrollToLetterOnWIndowResize';
import DeclarationCounter from './components/DeclarationCounter';

export type OnDirectionChangeCallback = (event: NavigationCommand) => void;

type Props = {
    currentSigns: DeclarationSignSummaryCollection;
    currentCursor: LetterCursor;
    noOfArticles: number;
    onDirectionChange: OnDirectionChangeCallback;
    onLetterClick: OnLetterlickCallback;
    onArticleClick: OnArticleClickCallback;
    children: JSX.Element;
};

export enum NavigationTarget {
    Article,
    TattooedLetter,
}

export enum NavigationDirection {
    Back,
    Forward,
}

export type NavigationCommand = {
    target: NavigationTarget;
    direction: NavigationDirection;
};

const DeclarationScroller: React.FC<Props> = ({
    currentSigns,
    currentCursor,
    noOfArticles,
    onDirectionChange,
    onLetterClick,
    onArticleClick,
    children,
}) => {
    useLockHtmlScroll(true);

    const onMoveToNextLetter = useCallback(() => {
        onDirectionChange({
            target: NavigationTarget.TattooedLetter,
            direction: NavigationDirection.Forward,
        });
    }, []);

    const moveToPreviousLetter = useCallback(() => {
        onDirectionChange({
            target: NavigationTarget.TattooedLetter,
            direction: NavigationDirection.Back,
        });
    }, []);

    // when the tattoo_owner_profile route matches, we know that an tattoo detail overlay is active
    const hasOpenOverlay = checkIfRouteNameIsInCurrentMatchedRoutes(
        'tattoo_owner_profile'
    );

    useScrollToLetterOnWindowResize(currentCursor);

    const articlesBefore = createNumberRangeArray(1, currentCursor.article - 1);
    const articlesAfter =
        currentCursor.article < noOfArticles
            ? createNumberRangeArray(currentCursor.article + 1, noOfArticles)
            : [];

    return (
        <SplitLayoutScrollWheelHandler
            upHandler={moveToPreviousLetter}
            downHandler={onMoveToNextLetter}
            leftHandler={onMoveToNextLetter}
            rightHandler={moveToPreviousLetter}
            timeout={0}
            pauseListeners={hasOpenOverlay}
        >
            <SplitLayoutMainContent>
                {articlesBefore.map(cursorArticle => (
                    <DeclarationArticle
                        articleNumber={cursorArticle}
                        key={cursorArticle}
                        onClick={onArticleClick}
                    />
                ))}
                <DeclarationArticle
                    articleNumber={currentCursor.article}
                    onClick={onArticleClick}
                >
                    {currentSigns.map(sign => {
                        const identifier = isTattooedLetter(sign)
                            ? `sign-${sign.number}`
                            : undefined;

                        const isCurrent =
                            isTattooedLetter(sign) &&
                            sign.number === currentCursor.letterNumber;

                        return (
                            <Sign
                                key={sign.id}
                                sign={sign}
                                identifier={identifier}
                                current={isCurrent}
                                onClick={onLetterClick}
                            />
                        );
                    })}
                </DeclarationArticle>
                {articlesAfter.map(cursorArticle => (
                    <DeclarationArticle
                        articleNumber={cursorArticle}
                        key={cursorArticle}
                        onClick={onArticleClick}
                    />
                ))}
            </SplitLayoutMainContent>
            <DeclarationCounter
                currentCount={currentCursor.letterNumber}
                totalCount={6773}
            />
            <SplitLayoutSide>{children}</SplitLayoutSide>
        </SplitLayoutScrollWheelHandler>
    );
};

export default DeclarationScroller;
