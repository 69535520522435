import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import SearchForm from './components/SearchForm';
import { RouteComponentProps, withRouter } from 'react-router';
import withReduxSuppliedPropsAndDispatchAccess, {
    ReduxSuppliedProps,
} from './hoc/withReduxSuppliedPropsAndDispatchAccess';
import { DispatchProp } from '../../redux/types';
import SearchResults from './components/SearchResults';
import SearchResultItem from './components/SearchResultItem';
import useRefetchResultsOnFilterChange from './hooks/useRefetchResultsOnFilterChange';
import { MatchedRoute, renderRoutes } from 'react-router-config';
import TattooedLetterPreview from '../tattooedLetterPreview/TattooedLetterPreview';
import useHighlightFirstResultIfNoneIsSet from './hooks/useHighlightFirstResult';
import {
    SplitLayoutMainContent,
    SplitLayoutScrollWheelHandler,
    SplitLayoutSide,
} from '../primitives/SplitLayout';
import useEnsureHighlightedResultIsCentered from './hooks/useEnsureHighlightedResultIsCentered';
import useLockHtmlScroll from '../../hooks/useLockHtmlScroll';
import {
    createOnItemClickHandler,
    createOnMoveToNextHandler,
    createOnMoveToPreviousHandler,
    createOnNextPageLoadRequiredHandler,
} from './handlers/navigationHandlers';
import { createOnFormValidSubmitHandler } from './handlers/formHandlers';
import withRouteMatchSuppliedProps from './hoc/withRouteMatchSuppliedProps';
import useLazyLoadNextPages from './hooks/useLazyLoadNextPages';
import { HIGHLIGHTED_QUERY } from '../../redux/actionFactory/searchResultsActionFactory';
import LoadingLayout from '../articleDetail/components/LoadingLayout';
import styled from 'styled-components';
import Heading, { TagOptions } from '../primitives/Heading';

export type OwnProps = {};

export type RouteParams = {
    query: string;
    article?: string;
    number?: string;
};

export type RouterProps = MatchedRoute<RouteParams>;

type CombinedProps = OwnProps &
    RouterProps &
    DispatchProp &
    ReduxSuppliedProps &
    RouteComponentProps;

const SearchResultsHeading = styled(Heading)`
    font-size: ${props => props.theme.fontSizes.smallFixed};
`;

const Search: React.FC<CombinedProps> = ({
    history,
    dispatch,
    results,
    pageCount,
    totalItemCount,
    currentPage,
    currentQuery,
    route,
    highlightedLetter,
    hasOpenOverlay,
}) => {
    // -----
    // Hooks
    // -----

    useLockHtmlScroll(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    useRefetchResultsOnFilterChange(
        currentPage,
        currentQuery,
        dispatch,
        setIsFetching
    );
    useHighlightFirstResultIfNoneIsSet(
        highlightedLetter,
        results,
        dispatch,
        currentQuery
    );
    useEnsureHighlightedResultIsCentered(
        highlightedLetter ? highlightedLetter.number : null
    );

    // --------------
    // Event handlers
    // --------------

    const onFormValidSubmit = createOnFormValidSubmitHandler(history);
    const onNextPageLoadRequired = createOnNextPageLoadRequiredHandler(
        setIsFetching,
        currentPage,
        currentQuery,
        dispatch
    );
    const onItemClick = createOnItemClickHandler(dispatch);
    const onMoveToNext = createOnMoveToNextHandler(
        results,
        highlightedLetter,
        dispatch
    );
    const onMoveToPrevious = createOnMoveToPreviousHandler(
        results,
        highlightedLetter,
        dispatch
    );
    useLazyLoadNextPages(
        pageCount,
        currentPage,
        isFetching,
        onNextPageLoadRequired,
        scrollRef
    );

    const isHighlightedOverview = currentQuery === HIGHLIGHTED_QUERY;

    if (isFetching) {
        return <LoadingLayout />;
    }

    return (
        <div>
            <Helmet>
                <title>Search - Human Rights Tattoo</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <SearchForm
                onValidSubmit={onFormValidSubmit}
                initialQuery={currentQuery}
                initialActiveState={!isHighlightedOverview}
                showBackToDeclarationButton={true}
            />
            <SplitLayoutScrollWheelHandler
                upHandler={onMoveToPrevious}
                downHandler={onMoveToNext}
                leftHandler={onMoveToNext}
                rightHandler={onMoveToPrevious}
                timeout={0}
                pauseListeners={hasOpenOverlay}
            >
                <SplitLayoutMainContent ref={scrollRef}>
                    {totalItemCount !== null && !isHighlightedOverview && (
                        <SearchResultsHeading tag={TagOptions.h3} uppercase>
                            Found {totalItemCount} result(s)
                        </SearchResultsHeading>
                    )}
                    {totalItemCount !== null && isHighlightedOverview && (
                        <SearchResultsHeading tag={TagOptions.h3} uppercase>
                            Featured stories
                        </SearchResultsHeading>
                    )}
                    {results && (
                        <SearchResults>
                            {results.map(tattooedLetter => {
                                const current =
                                    !!highlightedLetter &&
                                    highlightedLetter.id === tattooedLetter.id;

                                return (
                                    <SearchResultItem
                                        key={tattooedLetter.id}
                                        tattooedLetter={tattooedLetter}
                                        onClick={onItemClick}
                                        id={`sign-${tattooedLetter.number}`}
                                        current={current}
                                    />
                                );
                            })}
                        </SearchResults>
                    )}
                </SplitLayoutMainContent>
                {highlightedLetter && (
                    <SplitLayoutSide>
                        <TattooedLetterPreview
                            tattooedLetter={highlightedLetter}
                        />
                    </SplitLayoutSide>
                )}
            </SplitLayoutScrollWheelHandler>
            {renderRoutes(route.routes)}
        </div>
    );
};

export default withRouter(
    withRouteMatchSuppliedProps(withReduxSuppliedPropsAndDispatchAccess(Search))
);
