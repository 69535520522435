import React from 'react';
import styled from 'styled-components';

export type LoadNextpageCallback = () => Promise<any>;

type Props = {
    children: any;
};

const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const SearchResults: React.FC<Props> = ({ children }) => {
    return (
        <StyledList>
            {React.Children.map(children, child => (
                <li>{child}</li>
            ))}
        </StyledList>
    );
};

export default SearchResults;
