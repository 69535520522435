import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    colors: {
        default: '#b79378',
        primary: '#fff',
        dark: '#231000',
        bodyBg: '#170B01',
    },
    typography: {
        fontFamilyBase: "'Josefin Sans', sans-serif",
        fontFamilySecondary: "'Noticia Text', serif",
        fontFamilyTertiary: "'Open Sans', sans-serif",
    },
    fontSizes: {
        tinyFixed: '12px',
        tiny: '0.8rem',
        smallFixed: '14px',
        small: '0.875rem',
        base: '1rem',
        large: '1.35rem',
    },
    breakpoints: {
        xs: 321,
        sm: 576,
        md: 769,
        lg: 1024,
        xl: 1200,
        xxl: 1440,
    },
    zIndex: {
        elevatedContent: 1,
        navigation: 2,
        header: 3,
        searchForm: 3,
        portraitLink: 3,
        portraitSendPictureLink: 4,
        overlay: 4,
    },
};
