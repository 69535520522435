import App from '../components/App';
import { RouteConfig } from 'react-router-config';
import {
    createHomePath,
    createArticleDetailPath,
    createTattooOwnerProfilePath,
    createSearchPath,
    createSearchResultPath,
} from './urlGenerator/appUrlGenerator';
import Home from './../components/home/Home';
import ArticleDetail from '../components/articleDetail/ArticleDetail';
import TattooOwnerProfile from '../components/tattooedLetterDetail/TattooOwnerProfile';
import Search from '../components/search/Search';

// @todo add not found route and component

export interface ExtendedRouteConfig extends RouteConfig {
    component?: React.ComponentType<any>;
    routes?: ExtendedRouteConfig[];
    name: string;
}

const routes: ExtendedRouteConfig[] = [
    {
        name: 'app',
        component: App,
        routes: [
            {
                name: 'article_detail',
                path: createArticleDetailPath(),
                component: ArticleDetail,
                routes: [
                    {
                        name: 'tattoo_owner_profile',
                        path: createTattooOwnerProfilePath(),
                        exact: true,
                        component: TattooOwnerProfile,
                    },
                ],
            },
            {
                name: 'home',
                path: createHomePath(),
                exact: true,
                component: Home,
            },
            {
                name: 'search',
                path: createSearchPath(),
                component: Search,
                routes: [
                    {
                        name: 'search_result_detail',
                        path: createSearchResultPath(),
                        exact: true,
                        component: TattooOwnerProfile,
                    },
                ],
            },
        ],
    },
];

export default routes;
