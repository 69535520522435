import routing from './../routing';

export function generateRoute(
    path: string,
    params: { [key: string]: any } = {}
) {
    return routing.generate(path, params);
}

export function extractPathWithPlaceholders(routeName: string): string {
    const route = routing.getRoute(routeName);

    if (!route) {
        throw new Error(`No route found with name: '${routeName}'`);
    }

    const pathParts = route.tokens.map(tokenInfo => {
        const type = tokenInfo[0];
        const lastIndex = tokenInfo.length - 1;

        let pathPart;

        switch (type) {
            case 'variable':
                pathPart = `/:${tokenInfo[lastIndex]}`;
                break;

            case 'text':
                pathPart = tokenInfo[lastIndex];
                break;

            default:
                throw new Error(`Type ${type} not supported`);
        }

        return pathPart;
    });

    return pathParts.reverse().join('');
}
