import { SearchResultsReducerState } from './searchResultsReducer';
import { ActionType, getType } from 'typesafe-actions';
import * as actionFactories from '../actionFactory/searchResultsActionFactory';
import { TattooedLetterSummaryCollection } from '../../api/response/types';
import { RootAction } from '../types';
import produce from 'immer';

export type SearchResultsAction = ActionType<typeof actionFactories>;

export type PagedTattooedLetterSummaryCollection = {
    [page: number]: TattooedLetterSummaryCollection;
};

export type SearchResultsReducerState = {
    query: string;
    highlightedLetterNumber: number | null;
    pages: PagedTattooedLetterSummaryCollection;
    currentPage: number;
    pageCount: number;
    totalItemCount: number;
    itemNumberPerPage: number;
} | null;

const DEFAULT_STATE: SearchResultsReducerState = null;

const searchResultsReducer = (
    currentState: SearchResultsReducerState = DEFAULT_STATE,
    action: RootAction
): SearchResultsReducerState => {
    switch (action.type) {
        case getType(actionFactories.createFetchSearchResultsSuccessAction): {
            const { data: incomingData, query: incomingQuery } = action.payload;

            return produce<SearchResultsReducerState>(
                currentState,
                newState => {
                    const {
                        results,
                        pagination: {
                            currentPageNumber,
                            pageCount,
                            totalItemCount,
                            itemNumberPerPage,
                        },
                    } = incomingData;

                    // when the query changes, dispose of old data as this matches the previous query
                    if (!newState || newState.query !== incomingQuery) {
                        return {
                            query: incomingQuery,
                            highlightedLetterNumber:
                                results.length > 0 ? results[0].number : null,
                            currentPage: currentPageNumber,
                            pages:
                                results.length > 0
                                    ? {
                                          [currentPageNumber]: results,
                                      }
                                    : {},
                            pageCount: pageCount,
                            totalItemCount: totalItemCount,
                            itemNumberPerPage: itemNumberPerPage,
                        } as SearchResultsReducerState;
                    }

                    if (results.length > 0) {
                        newState.pages[currentPageNumber] = results;
                    }

                    newState.currentPage = currentPageNumber;
                    newState.pageCount = pageCount;
                    newState.totalItemCount = totalItemCount;
                    newState.itemNumberPerPage = itemNumberPerPage;

                    return newState;
                }
            );
        }

        case getType(actionFactories.createSetHighlightedLetterNumberAction): {
            const incomingHighlightedLetterNumber = action.payload;

            return produce<SearchResultsReducerState>(
                currentState,
                newState => {
                    if (!newState) {
                        return;
                    }

                    newState.highlightedLetterNumber = incomingHighlightedLetterNumber;
                }
            );
        }

        default:
            return currentState;
    }
};

export default searchResultsReducer;
