import { createStore as createReduxStore } from 'redux';
import { Store, GlobalState, RootAction } from '../types';
import { createRootReducer } from '../reducer/factory/rootReducerFactory';
import { createMiddlewareChain } from '../middleware/factory/middlewareFactory';

export function createStore(): Store {
    return createReduxStore<GlobalState, RootAction, {}, {}>(
        createRootReducer(),
        {},
        createMiddlewareChain()
    );
}
