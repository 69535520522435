import { useEffect } from 'react';

let counter = 0;
let originalOverflow: string | null = null;

const lock = () => {
    originalOverflow = window.getComputedStyle(document.documentElement)
        .overflow;
    document.documentElement.style.overflow = 'hidden';
};

const unlock = () => {
    if (originalOverflow) {
        document.documentElement.style.overflow = originalOverflow;
    }

    originalOverflow = null;
};

const increment = () => {
    counter++;
    if (counter === 1) {
        lock();
    }
};

const decrement = () => {
    counter--;
    if (counter === 0) {
        unlock();
    }
};

const useLockHtmlScroll = (enabled: boolean = true) => {
    useEffect(() => {
        if (enabled) {
            increment();

            return () => decrement();
        }

        return;
    }, [enabled]);
};

export default useLockHtmlScroll;
