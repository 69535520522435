import { TattooedLetterDetail } from '../../api/response/types';
import { ThunkAction } from '../types';
import { createApiTattooedLetterDetailPath } from '../../routing/urlGenerator/apiUrlGenerator';
import { executeGetRequest, CancelCallback } from '../../api/client';
import { TattooedLetterDetailResponse } from '../../api/response/types';
import { createAction } from 'typesafe-actions';

export const createFetchTattooedLetterDetailSuccessAction = createAction(
    '@tattooed-letter-detail/fetch/success',
    resolve => (tattooedLetter: TattooedLetterDetail) => resolve(tattooedLetter)
);

export const createClearTattooedLetterDetailAction = createAction(
    '@tattooed-letter-detail/clear'
);

export function createFetchTattooedletterDetail(
    article: number,
    tattooedLetterNumber: number
): ThunkAction<[Promise<any>, CancelCallback]> {
    return dispatch => {
        const path = createApiTattooedLetterDetailPath(
            article,
            tattooedLetterNumber
        );

        const [promise, cancel] = executeGetRequest<
            TattooedLetterDetailResponse
        >(path);

        const augmentedPromise = promise
            .then(response => response.data)
            .then(data =>
                dispatch(
                    createFetchTattooedLetterDetailSuccessAction(
                        data.tattooedLetter
                    )
                )
            );

        return [augmentedPromise, cancel];
    };
}
