import React, { useCallback, useRef } from 'react';
import DeclarationScroller, {
    OnDirectionChangeCallback,
} from '../declarationScroller/DeclarationScroller';
import { useFetchDeclaration } from '../../hooks/useDeclaration';
import { withRouter } from 'react-router-dom';
import {
    isTattooedLetter,
    TattooedLetterSummary,
    ArticleWithSignsSummaryCollection,
} from '../../api/response/types';
import { LetterCursor } from '../declarationScroller/utility/signCollectionNavigationHelper';
import TattooedLetterPreview from '../tattooedLetterPreview/TattooedLetterPreview';
import GetInvolved from '../getInvolved/GetInvolved';
import { renderRoutes, MatchedRoute } from 'react-router-config';
import { RouterProps } from 'react-router';
import { withGlobalStateAccess } from './hooks/useWithGlobalStateAccess';
import { OnClickCallback } from '../declarationScroller/components/DeclarationArticle';
import { OnClickCallback as OnLetterlickCallback } from './../declarationScroller/components/Sign';
import useReplaceHistoryPeriodically from './hooks/useReplaceHistoryPeriodically';
import { checkIfRouteNameIsInCurrentMatchedRoutes } from '../../routing/utility/routeMatchUtilities';
import SearchForm, {
    onValidSubmitCallback,
} from '../search/components/SearchForm';
import { createSearchPath } from '../../routing/urlGenerator/appUrlGenerator';
import Meta from './components/Meta';
import { Dispatch } from '../../redux/types';
import { useDispatch } from 'react-redux';
import {
    createDirectionChangeHandler,
    createOnArticleClickHandler,
    createOnLetterClickHandler,
    createOnFirstLetterCouldNotBeDeterminedHandler,
} from './handlers/navigationEventHandlers';
import { useStateWithRef } from '../../hooks/useStateWithRef';
import LoadingLayout from './components/LoadingLayout';

export type OwnProps = {};

type RouteParams = {
    article: string;
    number: string;
};

export type WithRouterProps = MatchedRoute<RouteParams>;

type CombinedProps = OwnProps & WithRouterProps & RouterProps;

export type SetNewCursorCallback = (newCursor: LetterCursor) => void;

const ArticleDetail: React.FC<CombinedProps> = ({ history, route, match }) => {
    const initialCursor = {
        article: parseInt(match.params.article, 10),
        letterNumber: parseInt(match.params.number, 10),
    };

    const [
        getCurrentCursor,
        setCurrentCursor,
        currentCursorState,
    ] = useStateWithRef<LetterCursor>(initialCursor);

    const {
        articles,
        noOfArticles,
        currentSearchQuery,
    } = withGlobalStateAccess(getCurrentCursor);

    // use ref for direct reference to articles even in stale callbacks
    const articlesRef = useRef<ArticleWithSignsSummaryCollection>();
    articlesRef.current = articles;

    const dispatch = useDispatch<Dispatch>();

    useFetchDeclaration(dispatch, getCurrentCursor().article);

    const setNewCursor: SetNewCursorCallback = useCallback(
        (newCursor: LetterCursor) => {
            const currentCursor = getCurrentCursor();

            if (
                newCursor.article !== currentCursor.article ||
                newCursor.letterNumber !== currentCursor.letterNumber
            ) {
                setCurrentCursor(newCursor);
            }
        },
        []
    );

    const onCursorToFirstLetterCouldNotBeDetermined = createOnFirstLetterCouldNotBeDeterminedHandler(
        getCurrentCursor,
        articlesRef,
        setNewCursor
    );

    const hasOpenOverlay = checkIfRouteNameIsInCurrentMatchedRoutes(
        'tattoo_owner_profile'
    );
    useReplaceHistoryPeriodically(getCurrentCursor, history, !hasOpenOverlay);

    const onDirectionChange: OnDirectionChangeCallback = createDirectionChangeHandler(
        getCurrentCursor,
        articlesRef,
        noOfArticles,
        setNewCursor
    );

    const onArticleClick: OnClickCallback = createOnArticleClickHandler(
        setNewCursor
    );

    const onLetterClick: OnLetterlickCallback = createOnLetterClickHandler(
        getCurrentCursor,
        setNewCursor
    );

    const onSearchFormSubmit: onValidSubmitCallback = query => {
        history.push(createSearchPath(query));
    };

    if (!articles || !noOfArticles) {
        return null;
    }

    const currentSigns = articles[currentCursorState.article];

    if (!currentSigns) {
        return <LoadingLayout />;
    }

    // sometimes we do not have the article data to determine at which letter number to start. Then we
    // supply -1, and have the logic below determine the first display-able letter number
    if (currentCursorState.letterNumber === -1) {
        onCursorToFirstLetterCouldNotBeDetermined();

        return null;
    }

    // @ts-ignore -> for some strange reason TS does not recognize that this is a TattooedLetterSummary
    const currentLetter: TattooedLetterSummary | undefined = currentSigns.find(
        cursorSign =>
            isTattooedLetter(cursorSign) &&
            cursorSign.number === currentCursorState.letterNumber
    );

    if (!currentLetter) {
        onCursorToFirstLetterCouldNotBeDetermined();

        return null;
    }

    return (
        <div>
            <Meta article={currentCursorState.article} />
            <SearchForm
                onValidSubmit={onSearchFormSubmit}
                initialQuery={currentSearchQuery}
                showBackToSearchButton={true}
            />
            <DeclarationScroller
                currentSigns={currentSigns}
                currentCursor={currentCursorState}
                noOfArticles={noOfArticles}
                onDirectionChange={onDirectionChange}
                onArticleClick={onArticleClick}
                onLetterClick={onLetterClick}
            >
                {currentLetter.isPut ? (
                    <TattooedLetterPreview tattooedLetter={currentLetter} />
                ) : (
                    <GetInvolved />
                )}
            </DeclarationScroller>
            {renderRoutes(route.routes)}
        </div>
    );
};

// @ts-ignore -> Redux connect does not know about the additional react-router-config props that are supplied
export default withRouter(ArticleDetail);
