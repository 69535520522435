import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as HeartIcon } from '../../../../images/icons/heart.svg';
import { up } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { createSearchPath } from '../../../routing/urlGenerator/appUrlGenerator';
import { HIGHLIGHTED_QUERY } from '../../../redux/actionFactory/searchResultsActionFactory';

// On mobile the navigation is diplayed on bottom of device
// But can be hidden when search is active
const StyledNav = styled.nav`
    position: fixed; /* fixed to prevent movement on swiping on mobile */
    z-index: ${props => props.theme.zIndex.navigation};
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 55px;
    padding: 0.75rem 20px;
    border-top: 1px solid #543c2a;
    font-size: ${props => props.theme.fontSizes.smallFixed};
    text-transform: uppercase;
    background-color: #2e1b0d;

    ${up('xs')} {
        padding: 0.75rem 40px;
    }

    ${up('sm')} {
        padding: 0.75rem 50px;
    }

    ${up('lg')} {
        top: 30px;
        right: calc(44vw + 30px);
        left: auto;
        bottom: auto;
        height: auto;
        border: none;
        background: none;
    }

    ${up('xl')} {
        top: 50px;
        right: calc(44vw + 50px);
    }
`;

const createMenuLinkStyles = css`
    display: inline-flex;
    align-items: center;
    margin-left: 1rem;
    text-decoration: none;
    color: rgba(234, 219, 207, 0.6); /* #eadbcf */

    &:hover {
        color: #fff;
    }

    .icon {
        display: none;

        ${up('sm')} {
            position: relative;
            top: -0.1em;
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            margin-left: 0.5rem;
        }

        ${up('lg')} {
            top: -0.2em;
            width: 1.5em;
            height: 1.5em;
        }
    }

    ${up('xl')} {
        margin-left: 2rem;
    }
`;

const StyledLink = styled(Link)`
    ${createMenuLinkStyles}
`;

const StyledExternalLink = styled.a`
    ${createMenuLinkStyles}
`;

const Navigation: React.FC = () => {
    return (
        <StyledNav role="navigation">
            <StyledLink to={createSearchPath(HIGHLIGHTED_QUERY)}>
                Stories
            </StyledLink>
            <StyledExternalLink href="https://info.humanrightstattoo.org/about/">
                About
            </StyledExternalLink>
            <StyledExternalLink href="https://info.humanrightstattoo.org/donate/">
                Donate
                <HeartIcon />
            </StyledExternalLink>
        </StyledNav>
    );
};

export default Navigation;
