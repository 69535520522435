import React, { useState, FormEvent } from 'react';
import TextField, {
    OnTextFieldChangeCallback,
    TypeOptions,
} from '../../primitives/form/TextField';
import { up, down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../../images/icons/search.svg';
import { ReactComponent as ArrowLeft } from '../../../../images/icons/arrow-left.svg';
import { ReactComponent as DeclarationIcon } from '../../../../images/icons/declaration.svg';
import {
    createSearchPath,
    createHomePath,
} from '../../../routing/urlGenerator/appUrlGenerator';
import { Link } from 'react-router-dom';
import { useClickOutside } from 'use-events';
import { getLastPathMatchingRoute } from '../../../repository/historyRepository';

export type onValidSubmitCallback = (query: string) => void;

const StyledSearchWrapper = styled.div`
    position: fixed;
    z-index: ${props => props.theme.zIndex.searchForm};
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 55px;
    padding: 0.75rem 20px;

    ${up('xs')} {
        padding: 0.75rem 40px;
    }

    ${up('sm')} {
        padding: 0.75rem 50px;
    }

    ${up('lg')} {
        top: 180px;
        left: 50px;
        bottom: auto;
        justify-content: space-between;
        align-items: flex-end;
        width: calc(56vw - 115px);
        max-width: 900px;
        padding: 0 0 0.75rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    ${up('xl')} {
        top: 220px;
        left: 150px;
        width: calc(56vw - 310px);
    }
`;

const StyledSearchForm = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 40px);

    ${up('xs')} {
        width: calc(100vw - 80px);
    }

    ${up('sm')} {
        width: 50vw;
    }

    ${up('lg')} {
        width: 100%;
    }
`;

const StyledSearchButton = styled.button`
    display: flex;
    padding: 0;
    border: none;
    background: none;
    color: ${props => props.theme.colors.default};
    font-size: 15px;
    text-align: left;

    &:hover,
    &:focus {
        outline: 0;
        color: ${props => props.theme.colors.primary};
    }

    ${up('lg')} {
        width: 100%;
    }
`;

const SearchSubmitButton = styled(StyledSearchButton)`
    position: absolute;
    top: 50%;
    right: 10px;
    display: inline-flex;
    width: auto !important;
    transform: translateY(-50%);
`;

const FormGroup = styled.div`
    position: relative;
    width: 100%;
    max-width: 400px;
`;

const StyledBackButton = styled(Link)`
    display: inline-flex;
    align-items: center;
    margin-left: 1rem;
    color: ${props => props.theme.colors.default};
    font-size: ${props => props.theme.fontSizes.tinyFixed};
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    ${down('sm')} {
        .button-text {
            display: none;
        }
    }

    ${up('sm')} {
        margin-left: 1.5rem;
    }

    .icon {
        position: relative;
        top: -0.1em;
        margin-right: 0.5rem;
        font-size: 15px;
    }

    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
    }
`;

type Props = {
    onValidSubmit: onValidSubmitCallback;
    initialQuery?: string;
    initialActiveState?: boolean;
    showBackToSearchButton?: boolean;
    showBackToDeclarationButton?: boolean;
};

const SearchForm: React.FC<Props> = ({
    onValidSubmit,
    initialQuery = '',
    initialActiveState = false,
    showBackToSearchButton = false,
    showBackToDeclarationButton = false,
}) => {
    const [query, setQuery] = useState<string>(initialQuery);
    const [isActive, setActive] = useState<boolean>(initialActiveState);

    const searchRef = React.useRef<HTMLDivElement>(null);
    useClickOutside([searchRef], () => setActive(false));

    const onChange: OnTextFieldChangeCallback = event => {
        setQuery(event.target.value);
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        // prevent backend submission
        event.preventDefault();

        const trimmedAndSafeQuery = query.trim().replace(/[^0-9a-z ]+/i, '');

        if (!trimmedAndSafeQuery) {
            return;
        }

        onValidSubmit(trimmedAndSafeQuery);
    };

    const onClickIcon = () => {
        setActive(true);
    };

    // @todo don't render or show on mobile
    const renderBackToDeclarationButtonIfRequired = () => {
        if (!showBackToDeclarationButton) {
            return null;
        }

        const to = getLastPathMatchingRoute('article_detail', createHomePath());

        return (
            <StyledBackButton to={to}>
                <DeclarationIcon />{' '}
                <span className="button-text">Back to declaration</span>
            </StyledBackButton>
        );
    };

    if (isActive) {
        return (
            <StyledSearchWrapper ref={searchRef}>
                <StyledSearchForm onSubmit={onSubmit}>
                    <FormGroup>
                        <TextField
                            type={TypeOptions.Search}
                            value={query}
                            onChange={onChange}
                            placeholder="Search by name, number or country"
                            autoFocus={true}
                            hasIcon={true}
                        />
                        <SearchSubmitButton type="submit">
                            <SearchIcon />
                        </SearchSubmitButton>
                        {/* @todo styling of 'back to declaration button' when textfield is active */}
                    </FormGroup>
                </StyledSearchForm>
                {renderBackToDeclarationButtonIfRequired()}
            </StyledSearchWrapper>
        );
    }

    return (
        <StyledSearchWrapper>
            <StyledSearchButton onClick={onClickIcon}>
                <SearchIcon />
            </StyledSearchButton>
            {showBackToSearchButton && initialQuery && (
                <StyledBackButton to={createSearchPath(initialQuery)}>
                    <ArrowLeft />{' '}
                    <span className="button-text">Back to search results</span>
                </StyledBackButton>
            )}
            {renderBackToDeclarationButtonIfRequired()}
        </StyledSearchWrapper>
    );
};

export default SearchForm;
