import React from 'react';
import { ImageBlock } from '../../../api/response/types';
import Image from '../../primitives/Image';

type Props = {
    block: ImageBlock;
};

const ImageContentBlock: React.FC<Props> = ({ block }) => (
    <Image
        alt={block.image.alt}
        caption={block.image.caption}
        src={block.image.src}
        title={block.image.title}
    />
);

export default ImageContentBlock;
