import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { createHomePath } from '../../../routing/urlGenerator/appUrlGenerator';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../../../../images/hrt-logo-white.svg';

const LogoWrapperLink = styled(Link)`
    position: fixed; /* fixed to prevent movement on swiping on mobile */
    z-index: ${props => props.theme.zIndex.header};
    top: 20px;
    left: 20px;

    ${up('sm')} {
        left: 30px;
    }

    ${up('lg')} {
        top: 30px;
        left: 50px;
    }

    ${up('xl')} {
        top: 50px;
    }
`;

const StyledLogo = styled(LogoSvg)`
    width: 65px;
    height: 75px;

    ${up('lg')} {
        width: 100px;
        height: 115px;
    }
`;

const Logo: React.FC = () => (
    <LogoWrapperLink to={createHomePath()}>
        <StyledLogo />
    </LogoWrapperLink>
);

export default Logo;
