import {
    getBooleanFromSessionStorage,
    saveBooleanToSessionStorage,
} from '../utility/sessionStorageUtility';

const videoHasPlacedSessionNamespace = 'video_has_played';

export const checkVideoHasPlayedForThisClient = (): boolean =>
    getBooleanFromSessionStorage(videoHasPlacedSessionNamespace);

export const saveVideoHasPlayedForThisClient = (): boolean =>
    saveBooleanToSessionStorage(videoHasPlacedSessionNamespace, true, true);
