import React from 'react';
import { Helmet } from 'react-helmet';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme/theme';
import Header from './header/Header';

type OwnProps = {};

type CombinedProps = OwnProps & RouteConfigComponentProps;

const App: React.FC<CombinedProps> = ({ route }) => {
    if (!route) {
        throw new Error(
            'Expecting route parameter to be available at this point'
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta
                            name="description"
                            content="THE UNIVERSAL DECLARATION OF HUMAN RIGHTS UNITED IN 6773 TATTOOED LETTERS"
                        />
                        <title>Human Rights Tattoo</title>
                    </Helmet>
                    <Header />
                    {renderRoutes(route.routes)}
                </>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
