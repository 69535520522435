import React from 'react';
import { VideoOembedBlock } from '../../../api/response/types';
import RawContent from '../../primitives/RawContent';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

type Props = {
    block: VideoOembedBlock;
};

const StyledVideoBlock = styled.article`
    margin: 2rem -15vw;
    padding: 2rem;
    background-image: linear-gradient(
        to top right,
        rgb(183, 147, 120),
        rgb(214, 177, 151)
    );

    ${up('sm')} {
        margin: 2rem 0 2rem -15vw;
    }

    /* break block out of container - values should match StyledContentIsland paddings */
    ${up('md')} {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-left: calc(-125px - 2rem - 125px);
        margin-right: 0;
    }

    ${up('lg')} {
        margin-left: calc(-190px - 2rem - 190px);
        margin-right: 0;
    }
`;

const StyledVideoMeta = styled.aside`
    width: 100%;
    font-family: ${props => props.theme.typography.fontFamilyTertiary};
    font-size: ${props => props.theme.fontSizes.small};
    color: ${props => props.theme.colors.dark};

    ${up('md')} {
        max-width: ${125 + 125}px;
        padding-right: 2rem;
    }

    ${up('lg')} {
        max-width: ${190 + 190}px;
    }
`;

const StyledVideoMetaSummary = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0.5rem;
    margin-top: 1rem;

    .label {
        padding-top: 8px;
        opacity: 0.5;
        text-transform: uppercase;
        font-family: ${props => props.theme.typography.fontFamilyBase};
        font-size: ${props => props.theme.fontSizes.smallFixed};
    }

    .value {
        font-family: ${props => props.theme.typography.fontFamilySecondary};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: 400;
    }

    ${up('md')} {
        margin-top: 0;
    }
`;

const StyledVideo = styled(RawContent)`
    position: relative;
    width: 100%;

    &:before {
        content: '';
        display: block;
        padding-top: ${(9 / 16) * 100}%;
    }

    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
`;

const VideoOembedContentBlock: React.FC<Props> = ({ block }) => (
    <StyledVideoBlock>
        <StyledVideo content={block.video.html} />
        <StyledVideoMeta>
            <StyledVideoMetaSummary>
                <span className="label">Author</span>
                <span className="value">{block.video.author}</span>
                <span className="label">About</span>
                <span className="value">{block.video.description}</span>
            </StyledVideoMetaSummary>
        </StyledVideoMeta>
    </StyledVideoBlock>
);

export default VideoOembedContentBlock;
