import { WithRouteMatchSuppliedProps } from './withRouteMatchSuppliedProps';
import { TattooedLetterSummary } from '../../../api/response/types';
import { PagedTattooedLetterSummaryCollection } from '../../../redux/reducer/searchResultsReducer';
import { GlobalState } from '../../../redux/types';
import { connect } from 'react-redux';
import { OwnProps, RouterProps } from '../Search';
import { TattooedLetterSummaryCollection } from '../../../api/response/types';
import { resolveHighlightedTattooedLetter } from '../utility/highlightedResultResolver';

export type ReduxSuppliedProps = {
    results: TattooedLetterSummaryCollection | null;
    pageCount: number | null;
    totalItemCount: number | null;
    itemNumberPerPage: number | null;
    currentPage: number;
    currentQuery: string;
    highlightedLetter: TattooedLetterSummary | null;
    hasOpenOverlay: boolean;
};

// @todo move to utility function?
function createSortedResultsArray(
    resultPages: PagedTattooedLetterSummaryCollection
): TattooedLetterSummaryCollection {
    const results: TattooedLetterSummaryCollection = [];

    const sortedPages = Object.keys(resultPages).sort((first, second) => {
        if (first === second) {
            return 0;
        }

        return parseInt(first) > parseInt(second) ? 1 : -1;
    });

    sortedPages.forEach((page: string) =>
        resultPages[parseInt(page)].map(tattooedLetter => {
            results.push(tattooedLetter);
        })
    );

    return results;
}

const withReduxSuppliedPropsAndDispatchAccess = connect<
    ReduxSuppliedProps,
    {},
    OwnProps & RouterProps & WithRouteMatchSuppliedProps,
    GlobalState
>(
    (
        { searchResults },
        { hasOpenOverlay, currentQuery }
    ): ReduxSuppliedProps => {
        const results = searchResults
            ? createSortedResultsArray(searchResults.pages)
            : null;

        const highlightedLetterNumber = searchResults
            ? searchResults.highlightedLetterNumber
            : null;

        const highlightedLetter =
            highlightedLetterNumber && results
                ? resolveHighlightedTattooedLetter(
                      highlightedLetterNumber,
                      results
                  )
                : null;

        return {
            results,
            pageCount: searchResults ? searchResults.pageCount : null,
            totalItemCount: searchResults ? searchResults.totalItemCount : null,
            itemNumberPerPage: searchResults
                ? searchResults.itemNumberPerPage
                : null,
            currentPage: searchResults ? searchResults.currentPage : 1,
            currentQuery,
            highlightedLetter,
            hasOpenOverlay,
        };
    }
);

export default withReduxSuppliedPropsAndDispatchAccess;
