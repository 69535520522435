import { LetterCursor } from './../../components/declarationScroller/utility/signCollectionNavigationHelper';
import {
    generateRoute,
    extractPathWithPlaceholders,
} from '../utility/urlGenerator';

export function createHomePath(): string {
    return generateRoute('home');
}

export function createArticleDetailPath(letterCursor?: LetterCursor): string {
    const routeName = 'article_detail';

    if (!letterCursor) {
        return extractPathWithPlaceholders(routeName);
    }

    return generateRoute(routeName, {
        article: letterCursor.article,
        number: letterCursor.letterNumber,
    });
}

export function createTattooOwnerProfilePath(
    article?: number,
    tattoedLetterNumber?: number
): string {
    const routeName = 'tattoo_owner_profile';

    if (!article || !tattoedLetterNumber) {
        return extractPathWithPlaceholders(routeName);
    }

    return generateRoute(routeName, { article, number: tattoedLetterNumber });
}

export function createSearchPath(query?: string): string {
    const routeName = 'search';

    if (!query) {
        return extractPathWithPlaceholders(routeName);
    }

    return generateRoute(routeName, { query });
}

export function createSearchResultPath(
    query?: string,
    article?: number,
    tattoedLetterNumber?: number
): string {
    const routeName = 'search_result';

    if (!query || !article || !tattoedLetterNumber) {
        return extractPathWithPlaceholders(routeName);
    }

    return generateRoute(routeName, {
        query,
        article,
        number: tattoedLetterNumber,
    });
}
