import React from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

interface OwnProps {
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
}

const StyledWrapper = styled.div<OwnProps>`
    position: absolute;
    z-index: ${props => props.theme.zIndex.portraitSendPictureLink};
    bottom: 2rem;
    left: 0;
    width: 100%;
    padding: 0 2rem;
    color: #fff;
    font-size: ${props => props.theme.fontSizes.small};
    text-transform: uppercase;
    text-align: center;

    a {
        white-space: nowrap;
    }

    /*
        // @ts-ignore not sure how to check ownProps with breakpoint props which now results in error */
    ${down('md')} {
        display: ${(props: OwnProps) =>
            props.hideOnMobile ? 'none' : 'block'};
    }

    /*
        // @ts-ignore not sure how to check ownProps with breakpoint props which now results in error */
    ${up('md')} {
        display: ${(props: OwnProps) =>
            props.hideOnDesktop ? 'none' : 'block'};
    }
`;

const SubmitPortraitPhoto: React.FC<OwnProps> = ({
    hideOnMobile = false,
    hideOnDesktop = false,
}) => {
    return (
        <StyledWrapper
            hideOnMobile={hideOnMobile}
            hideOnDesktop={hideOnDesktop}
        >
            Is this you?{' '}
            <a href="https://info.humanrightstattoo.org/upload-photo/">
                Send us your picture
            </a>
        </StyledWrapper>
    );
};

export default SubmitPortraitPhoto;
