// polyfills
// useBuiltIns - usage returns error in IE11 so for now maintain required polyfills ourselfs
// they do get checked by browserslist so this might become smaller when browser support gets better
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/string/pad-start';
import 'scroll-behavior-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './routing/appRoutes';
import { Provider as GlobalStateProvider } from 'react-redux';
import { createStore } from './redux/store/storeFactory';
import { initiateClientSideErrorLogging } from './utility/logger';
import {
    initialize as initializeHistory,
    getBrowserHistory,
} from './routing/utility/historyManager';
import { detectTouchInteraction } from './utility/environmentDetector';

initiateClientSideErrorLogging();
detectTouchInteraction();

const store = createStore();

initializeHistory();
const history = getBrowserHistory();

ReactDOM.render(
    <GlobalStateProvider store={store}>
        <Router history={history}>{renderRoutes(routes)}</Router>
    </GlobalStateProvider>,
    document.getElementById('hrt-app')
);
