import React from 'react';
import { BlockCollection } from '../../api/response/types';
import ContentBlock from './components/ContentBlock';
import styled from 'styled-components';

type Props = {
    items: BlockCollection;
};

const StyledBlockListSection = styled.section`
    margin: 4rem 0 2rem;
`;

const ContentBlockList: React.FC<Props> = ({ items }) => (
    <StyledBlockListSection>
        {items.map(block => (
            <ContentBlock key={block.id} block={block} />
        ))}
    </StyledBlockListSection>
);

export default ContentBlockList;
