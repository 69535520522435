import debounce from 'lodash/debounce';
import { LetterCursor } from './../utility/signCollectionNavigationHelper';
import { useEffect, useCallback } from 'react';
import imagesLoaded from 'imagesloaded';

export default function useScrollToLetterOnWindowResize(
    currentCursor: LetterCursor
) {
    const scrollToLetter = useCallback(
        (tattooedLetterNumber: number) => {
            const signElement = document.getElementById(
                `sign-${tattooedLetterNumber}`
            );

            // wait for images, this is important on mobile because else scroll position is wrong.
            imagesLoaded(document.body, () => {
                if (signElement) {
                    signElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            });
        },
        [currentCursor]
    );

    const onWindowResize = useCallback(
        debounce(() => scrollToLetter(currentCursor.letterNumber), 500),
        [currentCursor]
    );

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        // when current sign changes, scroll to sign
        scrollToLetter(currentCursor.letterNumber);

        return () => window.removeEventListener('resize', onWindowResize);
    }, [currentCursor]);
}
