import { logError } from './logger';

export function getBooleanFromSessionStorage(
    key: string,
    logOnError: boolean = false,
    fallback: boolean = false
): boolean {
    const value = getFromSessionStorage(key, logOnError);

    if (value === null) {
        return fallback;
    }

    return !!value;
}

export function getFromSessionStorage(
    key: string,
    logOnError: boolean = false
): string | null {
    try {
        return sessionStorage.getItem(key);
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return null;
}

export function saveBooleanToSessionStorage(
    key: string,
    value: boolean,
    logOnOrror: boolean = false
): boolean {
    const valueAsString = value ? '1' : '0';

    return setSessionStorage(key, valueAsString, logOnOrror);
}

export function setSessionStorage(
    key: string,
    values: object | string | number | boolean,
    logOnError: boolean = false
): boolean {
    try {
        if (typeof values === 'string') {
            sessionStorage.setItem(key, values);

            return true;
        }

        sessionStorage.setItem(key, JSON.stringify(values));

        return true;
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return false;
}

export function removeFromSessionStorage(
    key: string,
    logOnError: boolean = false
): boolean {
    try {
        sessionStorage.removeItem(key);

        return true;
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return false;
}
