import React, { ReactNode } from 'react';
import { StyledContentIsland } from '../styles/TattooOwnerProfileStyles';
import { useTransition } from 'react-spring';

type Props = {
    isActive: boolean;
    children: ReactNode;
};

const AnimatedContentIsland: React.FC<Props> = ({ isActive, children }) => {
    const transition = useTransition(
        { isActive },
        {
            from: { transform: 'translateY(100vh)' },
            enter: { transform: 'translateY(0)' },
        }
    );

    return (
        <>
            {transition((props, { isActive }) => {
                return (
                    isActive && (
                        <StyledContentIsland style={props}>
                            {children}
                        </StyledContentIsland>
                    )
                );
            })}
        </>
    );
};

export default AnimatedContentIsland;
