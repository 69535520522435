import { Router } from 'symfony-ts-router';

// @ts-ignore Typescript does not understand JSON
import routes from './routes.json';

const routing = new Router();

routing.setRoutingData(routes);

export default routing;
