import { TattooedLetterDetail } from '../../../api/response/types';
import {
    createArticleDetailPath,
    createSearchPath,
} from '../../../routing/urlGenerator/appUrlGenerator';
import { extractParamFromFirstRouteMatcheForCurrentPath } from '../../../routing/utility/routeMatchUtilities';

export function generateCloseUrl(tattooedLetter: TattooedLetterDetail) {
    const query = extractParamFromFirstRouteMatcheForCurrentPath('query');

    if (query && tattooedLetter.number) {
        return createSearchPath(query);
    }

    return createArticleDetailPath({
        article: tattooedLetter.article,
        letterNumber: tattooedLetter.number || -1,
    });
}
