import React from 'react';
import styled from 'styled-components';
import Button from '../../primitives/Button';
import Heading, { TagOptions } from '../../primitives/Heading';

const StyledDonateBlock = styled.article`
    margin: 2rem 0;
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.typography.fontFamilyTertiary};
    font-size: ${props => props.theme.fontSizes.small};
`;

const StyledHeading = styled(Heading)`
    font-family: ${props => props.theme.typography.fontFamilySecondary};
`;

const DonateBlock = () => {
    return (
        <StyledDonateBlock>
            <StyledHeading tag={TagOptions.h3} uppercase={false}>
                Make your donation now
            </StyledHeading>
            <p>
                The Human Rights Tattoo Foundation depends on donations. We aim
                to spread the project world wide and to bring Human Rights
                Tattoo to places and countries where human rights are not taken
                for granted. We will use your donation for the set up of Human
                Rights Tattoo events world wide. Donations of any amount are
                highly appreciated!
            </p>
            <p>
                We’re not looking for your six-figure donation. Everything above
                zero is a huge plus.
            </p>
            <Button href="https://info.humanrightstattoo.org/donate/" external>
                Donate now!
            </Button>
        </StyledDonateBlock>
    );
};

export default DonateBlock;
