import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as PlusButton } from '../../../../images/icons/plus.svg';
import { isTouchDevice } from '../../../utility/environmentDetector';
import { theme } from '../../../theme/theme';

interface Props {
    x: number;
    y: number;
    isHovered: boolean;
    wrapperWidth: number;
}

const StyledSpan = styled(animated.span)`
    position: absolute;
    z-index: ${props => props.theme.zIndex.elevatedContent};
    color: #fff;
    font-size: 3rem;
`;

const AnimatedDetailButton: React.FC<Props> = ({
    x,
    y,
    isHovered,
    wrapperWidth,
}) => {
    // small devices always use a fixed position
    // large screen only when they use touch input
    const springConfig =
        window.innerWidth < theme.breakpoints.lg
            ? {
                  to: {
                      top: 15,
                      right: 15,
                  },
                  immediate: isTouchDevice,
              }
            : {
                  to: {
                      top: isHovered && !isTouchDevice ? y : 70,
                      left:
                          isHovered && !isTouchDevice ? x : wrapperWidth - 140,
                  },
                  immediate: isTouchDevice,
              };

    const animatedStyles = useSpring(springConfig);

    return (
        <StyledSpan style={animatedStyles}>
            <PlusButton />
        </StyledSpan>
    );
};

export default AnimatedDetailButton;
