import { combineReducers } from 'redux';
import declarationReducer from '../declarationReducer';
import tattooedLetterDetailReducer from '../tattooedletterDetailReducer';
import searchResultsReducer from '../searchResultsReducer';

export function createRootReducer() {
    return combineReducers({
        declaration: declarationReducer,
        tattooedLetterDetail: tattooedLetterDetailReducer,
        searchResults: searchResultsReducer,
    });
}
