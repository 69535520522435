import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import imagesLoaded from 'imagesloaded';

export default function useEnsureHighlightedResultIsCentered(
    highlightedLetterNumber: number | null
) {
    const scrollToHighlightedResult = useCallback(
        (tattooedLetterNumber: number) => {
            const signElement = document.getElementById(
                `sign-${tattooedLetterNumber}`
            );

            // wait for images, this is important on mobile because else scroll position is wrong.
            imagesLoaded(document.body, () => {
                if (signElement) {
                    signElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            });
        },
        [highlightedLetterNumber]
    );

    const onHighlightedResultRecenteringRequired = useCallback(
        debounce(() => {
            if (highlightedLetterNumber) {
                scrollToHighlightedResult(highlightedLetterNumber);
            }
        }, 500),
        [highlightedLetterNumber]
    );

    useEffect(() => {
        onHighlightedResultRecenteringRequired();
    }, [highlightedLetterNumber]);

    useEffect(() => {
        window.addEventListener(
            'resize',
            onHighlightedResultRecenteringRequired
        );

        return () =>
            window.removeEventListener(
                'resize',
                onHighlightedResultRecenteringRequired
            );
    }, [highlightedLetterNumber]);
}
