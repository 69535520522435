import React from 'react';
import { TattooedLetterDetail } from '../../../api/response/types';
import { StyledProfileSummary } from '../styles/TattooOwnerProfileStyles';
import { formatDateTimeShort } from '../../../utility/dateTimeUtilities';
import { Link } from 'react-router-dom';
import { createArticleDetailPath } from '../../../routing/urlGenerator/appUrlGenerator';
import { formatNumberWithLeadingZeros } from '../../../utility/numberUtilities';

interface OwnProps {
    tattooedLetter: TattooedLetterDetail;
}

const ProfileSummary: React.FC<OwnProps> = ({ tattooedLetter }) => {
    const articleDetailPath = createArticleDetailPath({
        article: tattooedLetter.article,
        letterNumber: tattooedLetter.number || -1,
    });

    return (
        <StyledProfileSummary>
            <div>
                <span className="label">Tattoo</span>
                <span className="value">
                    <Link
                        to={articleDetailPath}
                        title="Show in declaration"
                    >{`#${formatNumberWithLeadingZeros(
                        tattooedLetter.number
                    )}`}</Link>
                </span>
                {tattooedLetter.createdAt && (
                    <>
                        <span className="label">Date</span>
                        <span className="value">{`${formatDateTimeShort(
                            tattooedLetter.createdAt
                        )}`}</span>
                    </>
                )}
            </div>
            {tattooedLetter.location && (
                <div>
                    <span className="label">Event</span>
                    <span className="value">{tattooedLetter.location}</span>
                </div>
            )}
        </StyledProfileSummary>
    );
};

export default ProfileSummary;
