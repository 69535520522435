import { useState, useEffect } from 'react';
import { createFetchDeclarationAction } from '../redux/actionFactory/declarationActionFactory';
import Axios, { AxiosError } from 'axios';
import { logError } from '../utility/logger';
import { Dispatch } from '../redux/types';

export function useFetchDeclaration(dispatch: Dispatch, article?: number) {
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (isFetching) {
            return;
        }

        setIsFetching(true);

        // @todo only fetch when the data is not already available
        const action = createFetchDeclarationAction(article);

        // @ts-ignore typescript does not know about thunk actions being allowed
        const promise = dispatch(action);

        promise
            .then(() => setIsFetching(false))
            .catch((error: AxiosError) => {
                setIsFetching(false);

                if (!Axios.isCancel(error)) {
                    logError(error);
                }
            });
    }, [article]);
}
