import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export enum ButtonTypes {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset',
}

interface Props {
    children: React.ReactNode;
    type?: ButtonTypes;
    external?: boolean;
    href?: string;
    isLight?: boolean;
    isLarge?: boolean;
}

type ButtonStyleProps = {
    href?: string;
    to?: string;
    isLight: boolean;
    isLarge: boolean;
};

const smallButtonMixin = css`
    padding: 0.6rem 1rem;
    border: 1px solid;
    font-size: ${props => props.theme.fontSizes.small};
`;

const largeButtonMixin = css`
    padding: 0.75rem 1.5rem;
    border: 2px solid;
    font-size: ${props => props.theme.fontSizes.large};
`;

const StyledButton = styled.button<ButtonStyleProps>`
    display: inline-flex;
    align-items: center;
    border-color: ${props =>
        props.isLight ? props.theme.colors.primary : props.theme.colors.dark};
    background: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: ${props => props.theme.typography.fontFamilyBase};
    color: ${props =>
        props.isLight ? props.theme.colors.primary : props.theme.colors.dark};
    transition: all 0.2s ease-in-out;

    ${props =>
        props.isLarge
            ? css`
                  ${largeButtonMixin}
              `
            : css`
                  ${smallButtonMixin}
              `}

    &:hover,
    &:focus {
        background-color: ${props =>
            props.isLight
                ? props.theme.colors.primary
                : props.theme.colors.dark};
        border-color: ${props =>
            props.isLight
                ? props.theme.colors.primary
                : props.theme.colors.dark};
        color: ${props =>
            props.isLight
                ? props.theme.colors.default
                : props.theme.colors.primary};
    }
`;

const Button: React.FC<Props> = ({
    children,
    type = ButtonTypes.Button,
    external = false,
    isLight = false,
    isLarge = false,
    href,
    ...otherProps
}) => {
    if (external && href) {
        return (
            <StyledButton
                as="a"
                href={href}
                isLight={isLight}
                isLarge={isLarge}
                {...otherProps}
            >
                {children}
            </StyledButton>
        );
    }

    if (href) {
        return (
            <StyledButton
                as={Link}
                to={href}
                isLight={isLight}
                isLarge={isLarge}
                {...otherProps}
            >
                {children}
            </StyledButton>
        );
    }

    return (
        <StyledButton
            type={type}
            isLight={isLight}
            isLarge={isLarge}
            {...otherProps}
        >
            {children}
        </StyledButton>
    );
};

export default Button;
