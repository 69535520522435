import { ThunkAction } from '../types';
import { createApiTattooedLetterSearchPath } from '../../routing/urlGenerator/apiUrlGenerator';
import { executeGetRequest } from '../../api/client';
import { TattooedletterSearchResponse } from '../../api/response/types';
import { createAction } from 'typesafe-actions';

export const HIGHLIGHTED_QUERY = 'stories';

export const createFetchSearchResultsSuccessAction = createAction(
    '@declaration-search-results/fetch/success',
    resolve => (data: TattooedletterSearchResponse, query: string) =>
        resolve({ data, query })
);

export const createClearSearchResultsAction = createAction(
    '@declaration-search-results/clear'
);

export const createSetHighlightedLetterNumberAction = createAction(
    '@declaration-search-results/highlighted-letter-number/set',
    resolve => (letterNumber: number) => resolve(letterNumber)
);

export function createFetchSearchResultsAction(
    query: string,
    page: number
): ThunkAction<Promise<any>> {
    // when the provided search query exuals HIGHLIGHTED_QUERY, it is applied as a filter
    // to only get highlighted results. This is a workaround to get highlighted search results
    // to work easier, without having to rewrite it all for two different result types
    const highlighted = query === HIGHLIGHTED_QUERY ? true : false;
    const searchQuery = highlighted ? '' : query;

    return dispatch => {
        const path = createApiTattooedLetterSearchPath(
            searchQuery,
            page,
            highlighted
        );

        const [promise] = executeGetRequest<TattooedletterSearchResponse>(path);

        const augmentedPromise = promise
            .then(response => response.data)
            .then(data =>
                dispatch(createFetchSearchResultsSuccessAction(data, query))
            );

        return augmentedPromise;
    };
}
