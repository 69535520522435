import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Heading, { TagOptions } from '../primitives/Heading';
import mozaicBgUrl from '../../../images/mozaic-bg.jpg';
import Button from '../primitives/Button';

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(50vh - 80px);
    margin: 0;
    padding: 1rem;
    background-color: ${props => props.theme.colors.default};
    background-image: url(${mozaicBgUrl});
    background-size: cover;
    background-position: center center;

    /* make background image darker */
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${props => props.theme.colors.bodyBg};
        opacity: 0.5;
    }

    ${up('lg')} {
        height: 100vh;
    }
`;

const StyledHeading = styled(Heading)`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.typography.fontFamilySecondary};
`;

const StyledContent = styled.div`
    position: relative;
    z-index: ${props => props.theme.zIndex.elevatedContent};
    text-align: center;
`;

const GetInvolved = () => {
    return (
        <StyledWrapper>
            <StyledContent>
                <StyledHeading tag={TagOptions.h4} uppercase={false}>
                    Help us finish
                    <br />
                    this project
                </StyledHeading>
                <Button
                    href="https://info.humanrightstattoo.org/get-involved/"
                    isLight={true}
                    isLarge={true}
                    external
                >
                    Get involved
                </Button>
            </StyledContent>
        </StyledWrapper>
    );
};

export default GetInvolved;
