import { checkIfPathNameMatchesRoute } from '../routing/utility/routeMatchUtilities';

const history: string[] = [];

export function getLastPathMatchingRoute(
    route: string,
    fallback: string
): string {
    const noOfItems = history.length;

    for (let index = noOfItems - 1; index >= 0; index--) {
        const cursorHistoryItem = history[index];

        if (checkIfPathNameMatchesRoute(cursorHistoryItem, route)) {
            return cursorHistoryItem;
        }
    }

    return fallback;
}

export function save(path: string, replace: boolean = false) {
    if (replace) {
        history[history.length - 1] = path;
    } else {
        history.push(path);
    }
}
