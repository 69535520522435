import { RootAction } from '../types';
import { DeclarationSignSummaryCollection } from '../../api/response/types';
import * as actionFactories from './../actionFactory/declarationActionFactory';
import { getType, ActionType } from 'typesafe-actions';
import produce from 'immer';

export type DeclarationAction = ActionType<typeof actionFactories>;

export type DeclarationReducerState = {
    articles: {
        [article: number]: DeclarationSignSummaryCollection;
    };
    noOfArticles: number;
    lastTattooedArticle: number;
};

export const DEFAULT_STATE: DeclarationReducerState = {
    articles: {},
    noOfArticles: -1,
    lastTattooedArticle: -1,
};

const declarationReducer = (
    currentState: DeclarationReducerState = DEFAULT_STATE,
    action: RootAction
) => {
    switch (action.type) {
        case getType(actionFactories.createFetchDeclarationSuccessAction): {
            const incomingData = action.payload;

            return produce<DeclarationReducerState>(currentState, newState => {
                newState.noOfArticles = incomingData.noOfArticles;

                newState.articles = {
                    ...newState.articles,
                    ...incomingData.articles,
                };

                newState.lastTattooedArticle = incomingData.lastTattooedArticle;

                return newState;
            });
        }

        default:
            return currentState;
    }
};

export default declarationReducer;
