import React from 'react';
import { TattooedLetterSummary } from '../../../api/response/types';
import styled, { keyframes } from 'styled-components';
import { createArticleDetailPath } from '../../../routing/urlGenerator/appUrlGenerator';
import { Link } from 'react-router-dom';
import Heading, { TagOptions } from '../../primitives/Heading';
import { formatNumberWithLeadingZeros } from '../../../utility/numberUtilities';
import { up } from 'styled-breakpoints';
import { ReactComponent as MeatballsIcon } from '../../../../images/icons/meatballs.svg';

type Props = {
    tattooedLetter: TattooedLetterSummary;
    onClick: (number: number) => void;
    current: boolean;
    id: string;
};

type StyledProps = {
    current: boolean;
};

const StyledListItem = styled.div<StyledProps>`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: ${props =>
        props.current
            ? props.theme.colors.primary
            : props.theme.colors.default};
    white-space: nowrap;
`;

const StyledTattooNumber = styled.span`
    display: none;

    ${up('sm')} {
        display: block;
        min-width: 2.5rem;
        margin-right: 5px;
        font-size: ${props => props.theme.fontSizes.smallFixed};
    }
`;

const StyledHeading = styled(Heading)`
    margin-right: 0.5rem;
    font-family: ${props => props.theme.typography.fontFamilySecondary};
    font-size: 1.25rem;
    line-height: 1;
    cursor: pointer;

    ${up('md')} {
        font-size: 1.5rem;
    }
`;

const StyledCountry = styled.span`
    align-self: flex-end;
    padding-bottom: 2px; /* correct bottom alignment with noticia font */
    font-family: ${props => props.theme.typography.fontFamilyTertiary};
    font-size: 0.75rem;
    line-height: 1;
`;

const fadeInLink = keyframes`
  from {
    transform: translateX(-200%);
    opacity: 0;
  }

  to {
    transform: translateX(-100%);
    opacity: 1;
  }
`;

const ShowInDeclarionLink = styled(Link)`
    position: absolute;
    left: -12px;
    display: inline-flex;
    animation: ${fadeInLink} 0.2s linear forwards;
`;

const SearchResultItem: React.FC<Props> = ({
    tattooedLetter,
    current,
    id,
    onClick: handleOnClick,
}) => {
    const tattooedLetterNumber = tattooedLetter.number;

    if (!tattooedLetterNumber) {
        return null;
    }

    const onClick = () => {
        if (tattooedLetter.number) {
            handleOnClick(tattooedLetter.number);
        }
    };

    const declarationsLinkOnHighlight = createArticleDetailPath({
        article: tattooedLetter.article,
        letterNumber: tattooedLetter.number || -1,
    });

    return (
        <StyledListItem current={current} onClick={onClick} id={id}>
            {current ? (
                <ShowInDeclarionLink
                    to={declarationsLinkOnHighlight}
                    title="Show in declaration"
                >
                    <MeatballsIcon />
                </ShowInDeclarionLink>
            ) : null}
            <StyledTattooNumber>
                {formatNumberWithLeadingZeros(tattooedLetter.number)}
            </StyledTattooNumber>
            <StyledHeading tag={TagOptions.h2} flatten={true}>
                {tattooedLetter.profile.name}
            </StyledHeading>
            <StyledCountry>
                {tattooedLetter.profile.countryOfOrigin}
            </StyledCountry>
        </StyledListItem>
    );
};

export default SearchResultItem;
