import {
    createSetHighlightedLetterNumberAction,
    createFetchSearchResultsAction,
} from './../../../redux/actionFactory/searchResultsActionFactory';
import { TattooedLetterSummary } from './../../../api/response/types';
import {
    determinePreviousHighlightedLetterNumber,
    determineNextHighlightedLetterNumber,
} from '../utility/resultNavigationHelpers';
import { Dispatch } from '../../../redux/types';
import { Dispatch as ReactDispatch, SetStateAction, useCallback } from 'react';

export const createOnMoveToPreviousHandler = (
    results: TattooedLetterSummary[] | null,
    highlightedLetter: TattooedLetterSummary | null,
    dispatch: Dispatch
) => () => {
    if (!results || !highlightedLetter) {
        return;
    }

    const previousNumber = determinePreviousHighlightedLetterNumber(
        highlightedLetter,
        results
    );

    if (previousNumber) {
        dispatch(createSetHighlightedLetterNumberAction(previousNumber));
    }
};

export const createOnMoveToNextHandler = (
    results: TattooedLetterSummary[] | null,
    highlightedLetter: TattooedLetterSummary | null,
    dispatch: Dispatch
) => () => {
    if (!results || !highlightedLetter) {
        return;
    }

    const nextNumber = determineNextHighlightedLetterNumber(
        highlightedLetter,
        results
    );

    if (nextNumber) {
        dispatch(createSetHighlightedLetterNumberAction(nextNumber));
    }
};

export const createOnNextPageLoadRequiredHandler = (
    setIsFetching: ReactDispatch<SetStateAction<boolean>>,
    currentPage: number,
    currentQuery: string,
    dispatch: Dispatch
) =>
    useCallback(() => {
        const nextPage = currentPage + 1;

        setIsFetching(true);

        const action = createFetchSearchResultsAction(currentQuery, nextPage);

        // @ts-ignore typescript does not know about thunk actions being allowed
        return dispatch(action).then(() => setIsFetching(false));
    }, [currentPage]);

export const createOnItemClickHandler = (dispatch: Dispatch) =>
    useCallback((letterNumber: number) => {
        dispatch(createSetHighlightedLetterNumberAction(letterNumber));
    }, []);
