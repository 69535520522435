import React from 'react';
import styled from 'styled-components';

type Props = {
    alt: string | null;
    title: string | null;
    caption: string | null;
    src: {
        desktop: string | null;
        mobile: string | null;
    };
};

const StyledCaption = styled.figcaption`
    margin-top: 0.5rem;
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.typography.fontFamilyTertiary};
    font-size: ${props => props.theme.fontSizes.smallFixed};
`;

const Image: React.FC<Props> = ({ alt, title, caption, src }) => {
    if (!src || !src.desktop || !src.mobile) {
        return null;
    }

    return (
        <figure>
            <picture>
                <source srcSet={src.desktop} media="(min-width: 48em)" />
                <source srcSet={src.mobile} />
                {/*
                // @ts-ignore image type does not support loading attribute yet */}
                <img
                    src={src.desktop}
                    alt={alt || ''}
                    title={title || ''}
                    loading="lazy"
                />
            </picture>
            {caption && (
                <StyledCaption dangerouslySetInnerHTML={{ __html: caption }} />
            )}
        </figure>
    );
};

export default Image;
