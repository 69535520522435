import React from 'react';
import styled from 'styled-components';

interface StyledInputProps {
    hasIcon?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
    display: flex;
    width: 100%;
    max-width: 400px;
    padding: 0.3rem 0.75rem; /* @todo theme variable? */
    padding-right: ${props => (props.hasIcon ? '1.75rem' : '0.75rem')};
    border: 0;
    background-color: #4b2f19; /* @todo theme variable? */
    background-image: none;
    background-clip: padding-box;
    font-family: ${props => props.theme.typography.fontFamilySecondary};
    font-size: ${props => props.theme.fontSizes.smallFixed};
    font-weight: 400;
    color: #fff; /* @todo theme variable? */

    /* CSS specific to iOS devices */
    @supports (-webkit-overflow-scrolling: touch) {
        font-size: 16px; /* on ios devices this can not be smaller than 16px otherwise it will zoom in */
    }

    /* @todo create a nice focus state that keeps it accessible */
    &:focus {
        outline: 0;
    }

    &::placeholder {
        color: #9c7f69;
        opacity: 1;
        font-size: ${props => props.theme.fontSizes.smallFixed};
    }

    &:disabled,
    &[readonly] {
        background-color: #ccc;
        opacity: 1;
    }
`;

export type OnTextFieldChangeCallback = (
    event: React.ChangeEvent<HTMLInputElement>
) => void;

export enum TypeOptions {
    Text = 'text',
    Search = 'search',
}

type Props = {
    value?: string;
    autoFocus?: boolean;
    onChange: OnTextFieldChangeCallback;
    placeholder?: string;
    type?: TypeOptions;
    hasIcon?: boolean;
};

const TextField: React.FC<Props> = ({
    onChange,
    value = '',
    autoFocus = false,
    placeholder = '',
    type = 'text',
    hasIcon = false,
}) => {
    return (
        <StyledInput
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus={autoFocus}
            hasIcon={hasIcon}
        />
    );
};

export default TextField;
