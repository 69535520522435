import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Link } from 'react-router-dom';

export const StyledFigure = styled.figure`
    position: relative;
    display: flex;
    width: 100%;
    margin: 0;

    figcaption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-shadow: 1px 1px #000;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    ${up('lg')} {
        max-width: none;
        height: 100vh;

        figcaption {
            font-size: 1.875rem;
        }
    }
`;

type PortraitImageProps = {
    isHovered: boolean;
};

export const StyledPortraitImage = styled.picture<PortraitImageProps>`
    width: 100%;
    height: calc(50vh - 80px);
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        transform: ${props => (props.isHovered ? 'scale(1.05)' : 'scale(1)')};
        transition: transform 0.2s ease-out;
        object-fit: cover;
    }

    /*
        // @ts-ignore not sure how to check ownProps with breakpoint props which now results in error */
    ${up('lg')} {
        height: 100vh;
    }
`;

export const StyledTattooImage = styled.img`
    position: absolute;
    bottom: 0;
    right: 15px;
    max-width: 80px;
    margin: 0;
    transform: translate(0, 50%);
    box-shadow: 0 0 50px #000;

    ${up('sm')} {
        max-width: 100px;
    }

    ${up('lg')} {
        top: 50%;
        left: 0;
        bottom: auto;
        transform: translate(-50%, -50%);
    }

    ${up('xl')} {
        max-width: 200px;
    }
`;

export const StyledDetailLink = styled(Link)`
    position: absolute;
    z-index: ${props => props.theme.zIndex.portraitLink};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
`;
