export enum DeclarationSignType {
    Tattoo = 'tattoo',
    Punctuation = 'punctuation',
}

interface ProfileImageSummary {
    id: number;
    image: {
        desktop: string;
        mobile: string;
    };
}

interface ProfileSummary {
    id: number;
    name: string | null;
    firstImage: ProfileImageSummary | null;
    countryOfOrigin: string | null;
}

interface ProfileDetail extends ProfileSummary {
    countryOfOrigin: string | null;
    images: ProfileImageSummary[];
}

export interface TattooedLetterSummary {
    id: number;
    article: number;
    sign: string;
    type: DeclarationSignType.Tattoo;
    number: number | null;
    isPut: boolean | null;
    image: string | null;
    profile: ProfileSummary;
}

interface Block {
    id: number;
    type: string;
}

export interface TextBlock extends Block {
    text: {
        html: string;
    };
}

export const isTextBlock = (block: Block): block is TextBlock =>
    block.type === 'fh_block.block.text';

export interface VideoOembedBlock extends Block {
    video: {
        name: string | null;
        provider: string;
        html: string;
        author: string | null;
        dimensions: {
            width: number;
            height: number;
        };
        thumbnailUrl: string;
        description: string | null;
    };
}

export const isVideoOembedBlock = (block: Block): block is VideoOembedBlock =>
    block.type === 'fh_block.block.video';

export interface ImageBlock extends Block {
    image: {
        src: {
            desktop: string;
            mobile: string;
        };
        alt: string | null;
        title: string | null;
        caption: string | null;
    };
}

export const isImageBlock = (block: Block): block is ImageBlock =>
    block.type === 'fh_block.block.image';

export type BlockCollection = (TextBlock | VideoOembedBlock | ImageBlock)[];

export interface TattooedLetterDetail extends TattooedLetterSummary {
    createdAt: string | null;
    location: string | null;
    profile: ProfileDetail;
    motivation: string | null;
    blocks: BlockCollection;
    highlighted: boolean;
}

export const isTattooedLetter = (
    sign: DeclarationSignSummary
): sign is TattooedLetterSummary => sign.type === DeclarationSignType.Tattoo;

export interface PunctuationSignSummary {
    id: number;
    sign: string;
    type: DeclarationSignType.Punctuation;
}

export const isPunctuationSign = (
    sign: DeclarationSignSummary
): sign is PunctuationSignSummary =>
    sign.type === DeclarationSignType.Punctuation;

export type DeclarationSignSummary =
    | TattooedLetterSummary
    | PunctuationSignSummary;

export type DeclarationSignSummaryCollection = DeclarationSignSummary[];

export type TattooedLetterSummaryCollection = TattooedLetterSummary[];

export type ArticleWithSignsSummaryCollection = {
    [article: number]: DeclarationSignSummaryCollection;
};

export interface DeclarationArticlesIndexResponse {
    articles: ArticleWithSignsSummaryCollection;
    noOfArticles: number;
    lastTattooedArticle: number;
}

export interface TattooedLetterDetailResponse {
    tattooedLetter: TattooedLetterDetail;
}

export interface PaginationInformation {
    currentPageNumber: number;
    pageCount: number;
    itemNumberPerPage: number;
    totalItemCount: number;
}

export interface TattooedletterSearchResponse {
    pagination: PaginationInformation;
    results: TattooedLetterSummaryCollection;
}
