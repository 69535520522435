const ENVIRONMENT_DEVELOPMENT = 'dev';
const ENVIRONMENT_PRODUCTION = 'production';

const currentEnvironment: string = process.env.NODE_ENV || 'production';

// @ts-ignore global var usage
window.USER_IS_TOUCHING = false;

export function getCurrentEnvironment(): string {
    return currentEnvironment;
}

export function isDevelopmentEnvironment(): boolean {
    return currentEnvironment === ENVIRONMENT_DEVELOPMENT;
}

export function isProductionEnvironment(): boolean {
    return currentEnvironment === ENVIRONMENT_PRODUCTION;
}

export const detectTouchInteraction = () => {
    window.addEventListener(
        'touchstart',
        function onFirstTouch() {
            // set global var that user is using touch device
            // @ts-ignore global var usage
            window.USER_IS_TOUCHING = true;

            // we only need to know once that a human touched the screen, so we can stop listening now
            window.removeEventListener('touchstart', onFirstTouch, false);
        },
        false
    );
};

// @ts-ignore global var usage
export const isTouchDevice: boolean = window.USER_IS_TOUCHING;
