import { createAction } from 'typesafe-actions';
import { createApiDeclarationArticlesIndexPath } from '../../routing/urlGenerator/apiUrlGenerator';
import { executeGetRequest } from '../../api/client';
import { DeclarationArticlesIndexResponse } from '../../api/response/types';
import { ThunkAction } from './../types';

export const createFetchDeclarationSuccessAction = createAction(
    '@declaration/fetch/success',
    resolve => (data: DeclarationArticlesIndexResponse) => resolve(data)
);

export function createFetchDeclarationAction(
    article?: number
): ThunkAction<Promise<any>> {
    return dispatch => {
        const path = createApiDeclarationArticlesIndexPath(article);

        const [promise] = executeGetRequest<DeclarationArticlesIndexResponse>(
            path
        );

        const augmentedPromise = promise
            .then(response => response.data)
            .then(data => dispatch(createFetchDeclarationSuccessAction(data)));

        return augmentedPromise;
    };
}
