import { useEffect } from 'react';

type OnUnmountCallback = () => any | void;
type OnMountCallback = () => OnUnmountCallback | void;

export function useOnMount(onMountCallback: OnMountCallback) {
    useEffect(() => {
        return onMountCallback();
    }, []);
}
