import React from 'react';
import { ErrorInfo } from './../ErrorBoundary';
import { isDevelopmentEnvironment } from '../../../utility/environmentDetector';

type Props = {
    error: Error;
    errorInfo: ErrorInfo | null;
};

export default class ErrorInformation extends React.Component<Props> {
    private renderErrorIfRequired() {
        const { error } = this.props;

        if (!isDevelopmentEnvironment()) {
            return null;
        }

        return <h2>{error.toString()}</h2>;
    }

    private renderErrorInfoIfRequired() {
        const { errorInfo } = this.props;

        if (!errorInfo || !isDevelopmentEnvironment()) {
            return null;
        }

        return (
            <>
                <strong>Component stack:</strong>
                <pre>{errorInfo.componentStack}</pre>
            </>
        );
    }

    public render() {
        return (
            <>
                <h1>Oops! Er is iets fout gegaan!</h1>

                <div className="u-margin--top-lg">
                    {this.renderErrorIfRequired()}
                    {this.renderErrorInfoIfRequired()}
                </div>
            </>
        );
    }
}
