import React from 'react';
import { TattooedLetterSummary } from '../../api/response/types';
import {
    StyledFigure,
    StyledPortraitImage,
    StyledTattooImage,
    StyledDetailLink,
} from './TattooedLetterPreviewStyles';
import portraitPlaceholderImageUrl from '../../../images/placeholder-portrait.jpg';
import { resolveDetailUrl } from './utility/detailUrlResolver';
import { useMousePosition, useHover, useResizeObserver } from 'use-events';
import AnimatedDetailButton from './components/AnimatedDetailButton';
import SubmitPortraitPhoto from '../submitPortraitPhoto/SubmitPortraitPhoto';

type Props = {
    tattooedLetter: TattooedLetterSummary;
};

const TattooedLetterPreview: React.FC<Props> = ({ tattooedLetter }) => {
    const [x, y, bindMousePosition] = useMousePosition();
    const [isHovered, bindHover] = useHover();

    const resizeRef = React.useRef(null);
    const [width] = useResizeObserver(resizeRef);

    const renderPortraitImage = () => {
        if (tattooedLetter.profile.firstImage) {
            // @todo use srcset to render mobile image (not yet available)
            return (
                <StyledPortraitImage isHovered={isHovered}>
                    <source
                        srcSet={tattooedLetter.profile.firstImage.image.mobile}
                        media="(max-width: 36em)"
                    />
                    <img
                        src={tattooedLetter.profile.firstImage.image.desktop}
                        alt={tattooedLetter.profile.name || ''}
                    />
                </StyledPortraitImage>
            );
        }

        return (
            <>
                <SubmitPortraitPhoto
                    hideOnMobile={true}
                    hideOnDesktop={false}
                />
                <StyledPortraitImage isHovered={isHovered}>
                    <img src={portraitPlaceholderImageUrl} alt="" />
                </StyledPortraitImage>
            </>
        );
    };

    return (
        <StyledFigure {...bindMousePosition} {...bindHover} ref={resizeRef}>
            {renderPortraitImage()}
            {tattooedLetter.image && (
                <StyledTattooImage
                    src={tattooedLetter.image}
                    alt={`Tattoo nummer ${tattooedLetter.number} - letter ${tattooedLetter.sign}`}
                />
            )}
            {!tattooedLetter.profile.firstImage && (
                <figcaption style={{ pointerEvents: 'none' }}>
                    {tattooedLetter.profile.name}
                </figcaption>
            )}
            <StyledDetailLink to={resolveDetailUrl(tattooedLetter)} />
            <AnimatedDetailButton
                x={x}
                y={y}
                isHovered={isHovered}
                wrapperWidth={width}
            />
        </StyledFigure>
    );
};

export default TattooedLetterPreview;
