import { generateRoute } from '../utility/urlGenerator';

export function createApiDeclarationArticlesIndexPath(
    article?: number
): string {
    const params = article ? { currentArticle: article } : {};

    return generateRoute('api_declaration_articles_index', params);
}

export function createApiTattooedLetterDetailPath(
    article: number,
    number: number
): string {
    return generateRoute('api_tattooed_letter_detail', { article, number });
}

export function createApiTattooedLetterSearchPath(
    query: string,
    page: number,
    highlighted: boolean = false
): string {
    return generateRoute('api_tattooed_letter_search', {
        query,
        page,
        highlighted: highlighted ? '1' : '0',
    });
}
