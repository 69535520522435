import React from 'react';
import Logo from './components/Logo';
import Navigation from './components/Navigation';
import useCheckIfHeaderShouldBeDisplayed from './hooks/useCheckIfHeaderShouldBeDisplayed';

const Header: React.FC = () => {
    const display = useCheckIfHeaderShouldBeDisplayed();

    if (!display) {
        return null;
    }

    return (
        <header>
            <Navigation />
            <Logo />
        </header>
    );
};

export default Header;
