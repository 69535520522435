import { extractArticleAndTattooedLetterNumberFromRouteMatch } from './../selector/articleAndTattooedLetterNumberSelector';
import { match } from 'react-router';
import { useEffect } from 'react';
import { createFetchTattooedletterDetail } from '../../../redux/actionFactory/tattooedLetterDetailActionFactory';
import { RouterParams } from '../TattooOwnerProfile';
import { Dispatch } from '../../../redux/types';

export function useFetchTattooedLetterDetailsOnRouteChange(
    match: match<RouterParams>,
    dispatch: Dispatch
) {
    const [
        article,
        tattooedLetterNumber,
    ] = extractArticleAndTattooedLetterNumberFromRouteMatch(match);

    useEffect(() => {
        const action = createFetchTattooedletterDetail(
            article,
            tattooedLetterNumber
        );

        // @ts-ignore typescript does not know about thunk actions being allowed
        dispatch(action);
    }, [article, tattooedLetterNumber]);
}
