import React from 'react';
import { Helmet } from 'react-helmet';
import { TattooedLetterDetail } from '../../../api/response/types';
import { checkIfRouteNameIsInCurrentMatchedRoutes } from '../../../routing/utility/routeMatchUtilities';

type Props = {
    tattooedLetter: TattooedLetterDetail;
};

const Meta: React.FC<Props> = ({ tattooedLetter }) => {
    const onSearchResultPage = checkIfRouteNameIsInCurrentMatchedRoutes(
        'search_result_detail'
    );

    return (
        <Helmet>
            <title>{`#${tattooedLetter.id} - ${tattooedLetter.profile.name} - Human Rights Tattoo`}</title>
            {onSearchResultPage && <meta name="robots" content="noindex" />}
        </Helmet>
    );
};

export default Meta;
