import { match } from 'react-router';
import { RouterParams } from '../TattooOwnerProfile';

export const extractArticleAndTattooedLetterNumberFromRouteMatch = (
    match: match<RouterParams>
): [number, number] => {
    const article = parseInt(match.params.article);

    if (isNaN(article)) {
        throw new Error("Could not extract 'article' from router params");
    }

    const number = parseInt(match.params.number);

    if (isNaN(number)) {
        throw new Error("Could not extract 'number' from router params");
    }

    return [article, number];
};
