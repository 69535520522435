import { LetterCursor } from './../../declarationScroller/utility/signCollectionNavigationHelper';
import { useCallback, useEffect } from 'react';
import { createArticleDetailPath } from '../../../routing/urlGenerator/appUrlGenerator';
import { History } from 'history';

const INTERVAL = 2000; // 2 seconds

export default function useReplaceHistoryPeriodically(
    getCurrentCursor: () => LetterCursor,
    history: History,
    enabled: boolean
) {
    const onIntervalOccurred = useCallback(() => {
        const cursor = getCurrentCursor();

        if (!cursor || !enabled) {
            return;
        }

        // sometimes -1 results in a loop
        if (cursor.letterNumber === -1) {
            return;
        }

        const newPath = createArticleDetailPath(cursor);

        if (location.pathname === newPath) {
            return;
        }

        history.replace(createArticleDetailPath(cursor));
    }, [enabled]);

    useEffect(() => {
        const handle = setInterval(onIntervalOccurred, INTERVAL);

        return () => clearInterval(handle);
    }, [enabled]);
}
