import { LoadNextpageCallback } from './../components/SearchResults';
import { useEffect, RefObject } from 'react';
import { throttle } from 'lodash';

export default function useLazyLoadNextPages(
    pageCount: number | null,
    currentPage: number,
    isFetching: boolean,
    loadNextPage: LoadNextpageCallback,
    wrapperRef: RefObject<HTMLElement>
) {
    useEffect(() => {
        if (wrapperRef.current) {
            checkHasReachedEndOfPage(wrapperRef.current);

            wrapperRef.current.addEventListener('scroll', onScrollHandler);
            window.addEventListener('resize', onWindowResize);
        }

        return () => {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener(
                    'scroll',
                    onScrollHandler
                );

                window.removeEventListener('resize', onWindowResize);
            }
        };
    }, [pageCount, currentPage, isFetching]);

    const onWindowResize = () => {
        if (wrapperRef.current) {
            checkHasReachedEndOfPage(wrapperRef.current);
        }
    };

    const onScrollHandler = throttle(
        (event: HTMLElementEventMap['scroll']) => {
            if (event.target instanceof HTMLElement) {
                checkHasReachedEndOfPage(event.target);
            }
        },
        300,
        {
            leading: false,
            trailing: true,
        }
    );

    const checkHasReachedEndOfPage = (element: HTMLElement) => {
        const hasMorePages = pageCount ? pageCount > currentPage : false;

        if (!hasMorePages || isFetching) {
            return;
        }

        if (
            element.scrollTop + 400 >=
            element.scrollHeight - element.clientHeight
        ) {
            loadNextPage();
        }
    };
}
