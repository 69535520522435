import { GlobalState } from './../../../redux/types';
import { connect } from 'react-redux';
import { OwnProps, WithRouterProps } from '../TattooOwnerProfile';
import { TattooedLetterDetail } from '../../../api/response/types';

export type ReduxSuppliedProps = {
    tattooedLetter: TattooedLetterDetail | null;
};

const mapGlobalStateToProps = ({
    tattooedLetterDetail,
}: GlobalState): ReduxSuppliedProps => {
    return {
        tattooedLetter: tattooedLetterDetail,
    };
};

const withTattooedLetterDetailAccess = connect<
    ReduxSuppliedProps,
    {},
    OwnProps & WithRouterProps,
    GlobalState
>(mapGlobalStateToProps);

export default withTattooedLetterDetailAccess;
