import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { animated } from 'react-spring';

export const StyledOverlay = styled.div`
    position: fixed;
    z-index: ${props => props.theme.zIndex.overlay};
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4rem 5vw;
    overflow-y: auto;

    ${up('sm')} {
        padding: 4rem 10vw;
    }

    ${up('md')} {
        padding: 3rem 2rem;
    }

    ${up('lg')} {
        padding: 3rem;
    }
`;

export const StyledOverlayBackdrop = styled(animated.div)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    cursor: pointer;
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto; /* fix flexbox overflow issue when centered content is larger than container */

    ${up('md')} {
        padding-left: 125px;
    }

    ${up('lg')} {
        /* create room for breakout blocks */
        padding-left: 190px;
    }
`;

export const StyledContentIsland = styled(animated.div)`
    position: relative;
    width: 100%;
    padding: 10vw;
    transform: translateY(100vh); /* will be animated */
    background-color: #fcfaf8;

    ${up('md')} {
        min-height: 435px;
        padding: 2rem 4rem 2rem calc(125px + 2rem);
    }

    ${up('lg')} {
        min-height: 660px;
        padding: 2rem 6rem 2rem calc(190px + 2rem);
    }
`;

export const StyledProfileImageContainer = styled.figure`
    position: relative;
    margin-top: calc(-2rem - 10vw);

    figcaption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-shadow: 1px 1px #000;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    ${up('md')} {
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        max-width: 250px;
        margin: 0;
        transform: translateX(-50%);
        text-align: center;
    }

    ${up('lg')} {
        max-width: 380px;
    }
`;

export const StyledPortraitImage = styled.img`
    width: 100%;
    height: auto;
`;

export const StyledTattooImage = styled.img`
    display: none;

    ${up('md')} {
        position: absolute;
        left: 50%;
        bottom: 0;
        display: block;
        max-width: 100px;
        transform: translate(-50%, 50%);
    }

    ${up('lg')} {
        max-width: 190px;
    }
`;

export const StyledCloseLink = styled.a`
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    font-size: 2rem;
    cursor: pointer;

    ${up('lg')} {
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
`;

export const StyledMotivation = styled.blockquote`
    margin: 2rem 0;
    font-family: ${props => props.theme.typography.fontFamilySecondary};
    font-size: 1.25rem;
    color: ${props => props.theme.colors.dark};
    font-weight: 700;

    ${up('md')} {
        margin: 4rem 0 3rem;
        font-size: 1.5rem;
    }

    cite {
        font-family: ${props => props.theme.typography.fontFamilyTertiary};
        font-style: normal;
        font-weight: 300;

        &:before {
            content: '— ';
        }
    }
`;

export const StyledProfileSummary = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 0.5rem;
    margin: 0 -15vw;
    padding: 1rem 2rem;
    background-color: rgb(214, 177, 151);
    color: ${props => props.theme.colors.dark};

    ${up('sm')} {
        margin: 0;
    }

    div {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 0.5rem;
    }

    .label {
        padding-top: 8px;
        opacity: 0.5;
        text-transform: uppercase;
        font-size: ${props => props.theme.fontSizes.smallFixed};
    }

    .value {
        font-family: ${props => props.theme.typography.fontFamilySecondary};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: 400;
    }

    a {
        color: ${props => props.theme.colors.dark};
    }
`;
