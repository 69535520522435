import { useCallback, useRef, useState } from 'react';

type TStateCreator = <TState>(oldState: TState) => TState;

/**
 * To be used when you need to use a `useRef` to be able to access the latest state of
 * a value, but also require a `useState` to ensure that the component is re-rendered
 * when the value changes. This hook ensures that always the latest value is returned,
 * and always a new value is set both to the ref and the state. It's purpose is to make
 * our component code more dry and less error prone.
 */
export const useStateWithRef = <TState>(
    initialState: TState
): [
    () => TState,
    (newState: TState | TStateCreator) => void,
    TState,
    React.MutableRefObject<TState>
] => {
    const [state, _setState] = useState<TState>(initialState);

    const ref = useRef<TState>(state);

    const setState = useCallback(newState => {
        if (typeof newState === 'function') {
            _setState(prevState => {
                const generatedState = newState(prevState);

                ref.current = generatedState;

                return generatedState;
            });
        } else {
            ref.current = newState;

            _setState(newState);
        }
    }, []);

    const getState = (): TState => {
        return ref.current;
    };

    return [getState, setState, state, ref];
};
