import React from 'react';
import {
    TextBlock,
    VideoOembedBlock,
    ImageBlock,
    isImageBlock,
    isVideoOembedBlock,
    isTextBlock,
} from '../../../api/response/types';
import ImageContentBlock from './ImageContentBlock';
import VideoOembedContentBlock from './VideoOembedContentBlock';
import TextContentBlock from './TextContentBlock';

type Props = {
    block: TextBlock | VideoOembedBlock | ImageBlock;
};

const ContentBlock: React.FC<Props> = ({ block }) => {
    if (isTextBlock(block)) {
        return <TextContentBlock block={block} />;
    }

    if (isImageBlock(block)) {
        return <ImageContentBlock block={block} />;
    }

    if (isVideoOembedBlock(block)) {
        return <VideoOembedContentBlock block={block} />;
    }

    return null;
};

export default ContentBlock;
