import React from 'react';
import { logError } from './../../utility/logger';
import ErrorInformation from './components/ErrorInformation';

export type ErrorInfo = {
    componentStack: string;
    [key: string]: any;
};

type Props = {
    children: JSX.Element | JSX.Element[];
};

type State = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    public componentDidCatch(
        error: Error | null = null,
        errorInfo: ErrorInfo | null = null
    ) {
        const normalizedError: Error = error
            ? error
            : new Error('Something went wrong');

        this.setState(
            (currentState: State): State => ({
                ...currentState,
                error: normalizedError,
                errorInfo,
            })
        );

        logError(normalizedError, { errorInfo });
    }

    public render() {
        const { error, errorInfo } = this.state;

        if (error) {
            return <ErrorInformation error={error} errorInfo={errorInfo} />;
        }

        return this.props.children;
    }
}
