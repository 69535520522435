import { useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/types';
import {
    LetterCursor,
    determineFirstPutTattooedLetterNumberInCollection,
} from '../../declarationScroller/utility/signCollectionNavigationHelper';

export type RedirectToValue = LetterCursor | null;

export default function useDetermineArticleToRedirectTo(): RedirectToValue {
    return useSelector<GlobalState, RedirectToValue>(({ declaration }) => {
        const { lastTattooedArticle, articles } = declaration;

        if (
            lastTattooedArticle <= 0 ||
            typeof articles[lastTattooedArticle] === 'undefined'
        ) {
            return null;
        }

        const tattooedLetterNumber = determineFirstPutTattooedLetterNumberInCollection(
            articles[lastTattooedArticle]
        );

        if (!tattooedLetterNumber) {
            return null;
        }

        return {
            article: declaration.lastTattooedArticle,
            letterNumber: tattooedLetterNumber,
        };
    });
}
