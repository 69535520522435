import { TattooedLetterSummary } from './../../../api/response/types';
import {
    createTattooOwnerProfilePath,
    createArticleDetailPath,
    createSearchResultPath,
} from '../../../routing/urlGenerator/appUrlGenerator';
import { extractParamFromFirstRouteMatcheForCurrentPath } from '../../../routing/utility/routeMatchUtilities';

export function resolveDetailUrl(
    tattooedLetter: TattooedLetterSummary
): string {
    const query = extractParamFromFirstRouteMatcheForCurrentPath('query');

    const { article, number } = tattooedLetter;

    // if we don't have a number, there is nothing we can link to
    if (!number) {
        return createArticleDetailPath({ article, letterNumber: number || -1 });
    }

    if (query) {
        return createSearchResultPath(query, article, number);
    }

    return createTattooOwnerProfilePath(article, number);
}
