import { createSetHighlightedLetterNumberAction } from './../../../redux/actionFactory/searchResultsActionFactory';
import { useEffect } from 'react';
import {
    TattooedLetterSummaryCollection,
    TattooedLetterSummary,
} from '../../../api/response/types';
import { Dispatch } from '../../../redux/types';

export default function useHighlightFirstResultIfNoneIsSet(
    highlightedLetter: TattooedLetterSummary | null,
    results: TattooedLetterSummaryCollection | null,
    dispatch: Dispatch,
    query: string
) {
    useEffect(() => {
        if (!highlightedLetter && results && results.length > 0) {
            const letterNumber = results[0].number;

            if (letterNumber) {
                dispatch(createSetHighlightedLetterNumberAction(letterNumber));
            }
        }
    }, [results, highlightedLetter, query]);
}
