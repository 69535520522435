import { createFetchSearchResultsAction } from '../../../redux/actionFactory/searchResultsActionFactory';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { Dispatch as ReduxDispatch } from '../../../redux/types';

let previousQuery: string | null = null;

const useRefetchResultsOnFilterChange = (
    page: number,
    query: string,
    dispatch: ReduxDispatch,
    setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
    useEffect(() => {
        const newPage = previousQuery !== query ? 1 : page;

        previousQuery = query;

        const action = createFetchSearchResultsAction(query, newPage);

        setIsLoading(true);

        // @ts-ignore typescript does not know about thunk actions being allowed
        dispatch(action).finally(() => setIsLoading(false));
    }, [page, query]);
};

export default useRefetchResultsOnFilterChange;
