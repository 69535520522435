import React from 'react';
import { MatchedRoute } from 'react-router-config';
import { checkIfRouteNameIsInCurrentMatchedRoutes } from '../../../routing/utility/routeMatchUtilities';

export type WithRouteMatchSuppliedProps = {
    currentQuery: string;
    hasOpenOverlay: boolean;
};

export type RouteParams = {
    query: string;
    article?: string;
    number?: string;
};

const withRouteMatchSuppliedProps = <P extends MatchedRoute<RouteParams>>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P & WithRouteMatchSuppliedProps> => (props: P) => {
    const currentQuery = props.match.params.query;

    // when the search_result_detail route matches, we know the overlay is active
    const hasOpenOverlay = checkIfRouteNameIsInCurrentMatchedRoutes(
        'search_result_detail'
    );

    return (
        <WrappedComponent
            {...props}
            currentQuery={currentQuery}
            hasOpenOverlay={hasOpenOverlay}
        />
    );
};

export default withRouteMatchSuppliedProps;
