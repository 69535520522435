import {
    TattooedLetterSummaryCollection,
    TattooedLetterSummary,
} from '../../../api/response/types';

export function resolveHighlightedTattooedLetter(
    letterNumber: number,
    results: TattooedLetterSummaryCollection
): TattooedLetterSummary | null {
    return (
        results.find(cursorResult => cursorResult.number === letterNumber) ||
        null
    );
}
