import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Heading from '../../primitives/Heading';
import React from 'react';

type StyledHeadingProps = {
    onClick: (event: React.MouseEvent) => void;
};

export const StyledDeclarationArticle = styled.article`
    max-width: 900px;
    font-family: ${props => props.theme.typography.fontFamilySecondary};
    font-weight: 700;
`;

export const StyledHeading = styled(Heading)<StyledHeadingProps>`
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: ${props => props.theme.fontSizes.tiny};
`;

export const StyledParagraph = styled.p`
    font-size: 1.25rem;

    ${up('md')} {
        font-size: 1.5rem;
    }
`;
