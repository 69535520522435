import { matchRoutes } from 'react-router-config';
import appRoutes from '../appRoutes';

export function extractParamFromFirstRouteMatcheForCurrentPath(
    field: string
): string | null {
    const matchedRoutesAndMatches = matchRoutes(appRoutes, location.pathname);

    for (let i = 0, l = matchedRoutesAndMatches.length; i < l; i++) {
        const matchedRouteAndMatch = matchedRoutesAndMatches[i];
        const params = matchedRouteAndMatch.match.params;

        // @ts-ignore -> Typescript does not know about the contents of the route params
        if (typeof params[field] !== 'undefined') {
            // @ts-ignore -> Typescript does not know about the contents of the route params
            return params[field];
        }
    }

    return null;
}

export const checkIfRouteNameIsInCurrentMatchedRoutes = (
    routeName: string
): boolean => checkIfPathNameMatchesRoute(location.pathname, routeName);

export function checkIfPathNameMatchesRoute(
    pathName: string,
    routeName: string
): boolean {
    const matchedRoutesAndMatches = matchRoutes(appRoutes, pathName);

    for (let i = 0, l = matchedRoutesAndMatches.length; i < l; i++) {
        const { route } = matchedRoutesAndMatches[i];

        if (route.name === routeName) {
            return true;
        }
    }

    return false;
}
