import { createBrowserHistory, History } from 'history';
import { save } from '../../repository/historyRepository';

let history: History | undefined;

const onHistoryChange: History.LocationListener = location => {
    if (history) {
        save(location.pathname, history.action === 'REPLACE');
    }
};

export function initialize() {
    history = createBrowserHistory();
    history.listen(onHistoryChange);

    // also save the url path the client landed on
    save(location.pathname, false);
}

export function getBrowserHistory(): History {
    if (!history) {
        throw new Error(
            'Call initialize() first to initialize the browser history'
        );
    }

    return history;
}
