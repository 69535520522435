import { ArticleWithSignsSummaryCollection } from './../../../api/response/types';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/types';
import { LetterCursor } from '../../declarationScroller/utility/signCollectionNavigationHelper';

type GlobalStateProps = {
    articles?: ArticleWithSignsSummaryCollection;
    noOfArticles?: number;
    currentSearchQuery?: string;
};

export const withGlobalStateAccess = (getCurrentCursor: () => LetterCursor) => {
    return useSelector<GlobalState, GlobalStateProps>(
        ({ declaration, searchResults }) => {
            const noOfArticles =
                declaration.noOfArticles !== -1
                    ? declaration.noOfArticles
                    : null;

            if (!noOfArticles) {
                return {};
            }

            const currentCursor = getCurrentCursor();

            if (!currentCursor) {
                return {};
            }

            const currentSearchQuery = searchResults
                ? searchResults.query
                : undefined;

            return {
                articles: declaration.articles,
                noOfArticles,
                currentSearchQuery,
            };
        }
    );
};
