import React from 'react';

type Props = {
    content: string;
    [key: string]: any;
};

const RawContent: React.FC<Props> = ({ content, ...otherProps }) => (
    <div {...otherProps} dangerouslySetInnerHTML={{ __html: content }} />
);

export default RawContent;
