import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { DispatchProp } from '../../redux/types';
import withTattooedLetterDetailAccess, {
    ReduxSuppliedProps,
} from './hoc/withTattooedLetterDetailAccess';
import { useFetchTattooedLetterDetailsOnRouteChange } from './hooks/useFetchTattooedLetterDetailsOnRouteChange';
import {
    StyledOverlay,
    StyledCloseLink,
    StyledContentWrapper,
    StyledProfileImageContainer,
    StyledPortraitImage,
    StyledTattooImage,
    StyledMotivation,
    StyledOverlayBackdrop,
} from './styles/TattooOwnerProfileStyles';
import { ReactComponent as CloseIcon } from '../../../images/icons/close.svg';
import portraitPlaceholderImageUrl from '../../../images/placeholder-portrait.jpg';
import ProfileSummary from './components/ProfileSummary';
import { generateCloseUrl } from './utility/closeUrlGenerator';
import { useSpring, config } from 'react-spring';
import { createClearTattooedLetterDetailAction } from '../../redux/actionFactory/tattooedLetterDetailActionFactory';
import Meta from './components/Meta';
import AnimatedContentIsland from './components/AnimatedContentIsland';
import { createHomePath } from '../../routing/urlGenerator/appUrlGenerator';
import ContentBlockList from '../contentBlockList/ContentBlockList';
import SubmitPortraitPhoto from '../submitPortraitPhoto/SubmitPortraitPhoto';
import { removeLineBreaksFromText } from '../../utility/textUtilities';
import DonateBlock from './components/DonateBlock';

export type OwnProps = {};

export type RouterParams = {
    article: string;
    number: string;
};

export type WithRouterProps = RouteComponentProps<RouterParams>;

type CombinedProps = WithRouterProps &
    OwnProps &
    DispatchProp &
    ReduxSuppliedProps;

const TattooOwnerProfile: React.FC<CombinedProps> = ({
    match,
    dispatch,
    tattooedLetter,
    history,
}) => {
    // We keep a local state to be able to create a leave animation
    const [overlayIsVisible, setOverlayIsVisible] = useState<boolean>(true);

    useFetchTattooedLetterDetailsOnRouteChange(match, dispatch);

    const onCloseOverlayClick = useCallback(() => {
        setOverlayIsVisible(false);

        dispatch(createClearTattooedLetterDetailAction());

        if (tattooedLetter) {
            history.push(generateCloseUrl(tattooedLetter));
        } else {
            history.push(createHomePath());
        }
    }, [tattooedLetter, dispatch, history]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onCloseOverlayClick();
            }
        };

        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown);
    }, [onCloseOverlayClick]);

    const backdropAnimationStyles = useSpring({
        to: {
            opacity: tattooedLetter && overlayIsVisible ? 0.8 : 0,
        },
        config: config.slow,
    });

    if (!tattooedLetter) {
        return <div>Loading..</div>;
    }

    const renderProfileImagePortrait = () => {
        if (tattooedLetter.profile.firstImage) {
            return (
                <StyledPortraitImage
                    src={tattooedLetter.profile.firstImage.image.mobile}
                    alt={tattooedLetter.profile.name || ''}
                />
            );
        }

        return (
            <>
                <SubmitPortraitPhoto
                    hideOnMobile={false}
                    hideOnDesktop={true}
                />
                <StyledPortraitImage src={portraitPlaceholderImageUrl} />
            </>
        );
    };

    const renderMotivation = () => {
        if (tattooedLetter.motivation !== null) {
            return (
                <StyledMotivation>
                    <p>{removeLineBreaksFromText(tattooedLetter.motivation)}</p>
                    <cite>{`${tattooedLetter.profile.name}, ${tattooedLetter.profile.countryOfOrigin}`}</cite>
                </StyledMotivation>
            );
        }
        return;
    };

    return (
        <StyledOverlay>
            <Meta tattooedLetter={tattooedLetter} />
            <StyledOverlayBackdrop
                style={backdropAnimationStyles}
                onClick={onCloseOverlayClick}
            />
            <StyledCloseLink onClick={onCloseOverlayClick}>
                <CloseIcon />
            </StyledCloseLink>
            <StyledContentWrapper>
                <AnimatedContentIsland isActive={overlayIsVisible}>
                    <StyledProfileImageContainer>
                        {renderProfileImagePortrait()}
                        {tattooedLetter.image && (
                            <StyledTattooImage
                                src={tattooedLetter.image}
                                alt={`Tattoo nummer ${tattooedLetter.number} - letter ${tattooedLetter.sign}`}
                            />
                        )}
                        {!tattooedLetter.profile.firstImage && (
                            <figcaption>
                                {tattooedLetter.profile.name}
                            </figcaption>
                        )}
                    </StyledProfileImageContainer>
                    {renderMotivation()}
                    <ProfileSummary tattooedLetter={tattooedLetter} />
                    {tattooedLetter.blocks.length > 0 && (
                        <ContentBlockList items={tattooedLetter.blocks} />
                    )}
                    {tattooedLetter.highlighted && <DonateBlock />}
                </AnimatedContentIsland>
            </StyledContentWrapper>
        </StyledOverlay>
    );
};

export default withRouter(withTattooedLetterDetailAccess(TattooOwnerProfile));
